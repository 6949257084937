import { math } from "../../math";

export interface Bezier{
	cp1:math.Vec2;
	cp2:math.Vec2;
	p:math.Vec2;
}

const tmpV2A=new math.Vec2();

function fixRadius(
	b:math.Vec2,//begin
	e:math.Vec2,//end
	r:math.Vec2,
	sin_th:number,
	cos_th:number,
){
	r=r.clone().abs();
	const eb=tmpV2A.subVectors(b,e);
	const px=(cos_th*eb.x+sin_th*eb.y)/2;
	const py=(cos_th*eb.y-sin_th*eb.x)/2;
	let pl=px*px/(r.x*r.x)+py*py/(r.y*r.y);
	if(pl>1)
		r.multiplyScalar(Math.sqrt(pl));
	return r;

}

export function arcToBezier(
	b:math.Vec2,//begin
	e:math.Vec2,//end
	r:math.Vec2,//radius
	large:number,
	sweep:number,
	rotateX:number,
){
	const sin_th=Math.sin(rotateX);
	const cos_th=Math.cos(rotateX);
	r=fixRadius(b,e,r,sin_th,cos_th);

	const a00=cos_th/r.x;
	const a01=sin_th/r.x;
	const a10=-sin_th/r.y;
	const a11=cos_th/r.y;
	const x0=a00*b.x+a01*b.y;
	const y0=a10*b.x+a11*b.y;
	const x1=a00*e.x+a01*e.y;
	const y1=a10*e.x+a11*e.y;
	const d=(x1-x0)*(x1-x0)+(y1-y0)*(y1-y0);

	let sfactor_sq=1/d-0.25;
	let sfactor=Math.sqrt(math.max(0,sfactor_sq));

	if(sweep === large){
		sfactor=-sfactor;
	}

	const c=new math.Vec2(
		0.5*(x0+x1)-sfactor*(y1-y0),
		0.5*(y0+y1)+sfactor*(x1-x0),
	);
	const th0=Math.atan2(y0-c.y, x0-c.x);
	const th1=Math.atan2(y1-c.y, x1-c.x);
	let th_arc=th1-th0;

	if(th_arc<0 && sweep === 1){
		th_arc+=2*Math.PI;
	} else if(th_arc>0 && sweep === 0){
		th_arc -= 2*Math.PI;
	}

	const segments=Math.ceil(Math.abs(th_arc/(Math.PI*0.5+0.001)));
	const result:Bezier[]=[];

	for (let i=0;i<segments;i++){
		const th2=th0+i*th_arc/segments;
		const th3=th0+(i+1)*th_arc/segments;
		//result[i]=[xc, yc, th2, th3, r.x, r.y, sin_th, cos_th];
		result[i]=segmentToBezier(
			c,
			th2,
			th3,
			r,
			cos_th,
			sin_th,
		);
	}

	return result;
}

function segmentToBezier(
	c:math.Vec2,
	th0:number,
	th1:number,
	r:math.Vec2,
	cos_th:number,
	sin_th:number,
){
	const a00=cos_th*r.x;
	const a01=-sin_th*r.y;
	const a10=sin_th*r.x;
	const a11=cos_th*r.y;
	const th_half=0.5*(th1-th0);
	const t=8/3*Math.sin(th_half*0.5)*Math.sin(th_half*0.5)/Math.sin(th_half);
	const x1=c.x+Math.cos(th0)-t*Math.sin(th0);
	const y1=c.y+Math.sin(th0)+t*Math.cos(th0);
	const x3=c.x+Math.cos(th1);
	const y3=c.y+Math.sin(th1);
	const x2=x3+t*Math.sin(th1);
	const y2=y3-t*Math.cos(th1);
	const cp1=new math.Vec2(a00*x1+a01*y1, a10*x1+a11*y1);
	const cp2=new math.Vec2(a00*x2+a01*y2, a10*x2+a11*y2);
	const p=new math.Vec2(a00*x3+a01*y3, a10*x3+a11*y3);
	return {cp1,cp2,p};
}
