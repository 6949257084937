import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'keyValueNoSort'
})
export class KeyValueNoSortPipe implements PipeTransform {

  transform(object:Record<string,any>|Map<any,any>){
		const array:{key:string, value:string | number}[]=[];
		if(object instanceof Map){
			for(const [key,value] of object)
				array.push({key,value});
		}else{
			for(const key in object)
				array.push({key, value: object[key]});
		}
		return array;
	}
}
