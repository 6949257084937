<report-page [footer]="false" class="landscape" title = {{title}}>
	<div class="full-height full-width flex-column">
		<div class="full-width">
			<div class="strip bar-1"></div>
			<div class="strip bar-2"></div>
			<div class="strip bar-3"></div>
		</div>
		<div class="flex-row start">
			<img [src]="'/assets/images/report-logo.svg'" [width]="150" [height]="150">
		</div>
		<div class="flex-size-fill flex-column justify-around items-center">
			<div class="report-title">
				{{title}}
			</div>
			<div class="report-info">
				<div style="grid-area: 1/1">
					Title
				</div>
				<div style="grid-area: 1/2">
					{{reporterTitle}}
				</div>
				<div style="grid-area: 2/1">
					Date
				</div>
				<div style="grid-area: 2/2">
					{{reportDate | date:'mediumDate'}}
				</div>
				<div style="grid-area: 1/3">
					Name
				</div>
				<div style="grid-area: 1/4">
					{{reporterName}}
				</div>
				<div style="grid-area: 2/3">
					Signature
				</div>
				<div class="signature" style="grid-area: 2/4">
					<div class="spacer">First LastName</div>
				</div>
			</div>
		</div>
		<div class="flex-row end">
			<img [src]="'/assets/images/report-logo.svg'" [width]="150" [height]="150">
		</div>
		<div class="full-width">
			<div class="strip bar-1"></div>
			<div class="strip bar-2"></div>
			<div class="strip bar-3"></div>
		</div>
	</div>
</report-page>
