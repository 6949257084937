<div class="controls">
	<div class="row text-center">
		<span *ngIf="domainName">
			{{domainName}}
		</span>
		<span *ngIf="subDomainName">
			/&nbsp; {{subDomainName}}
		</span>
		<span *ngIf="siteName">
			/&nbsp; {{siteName}}
		</span>
		{{title}}
	</div>
	<div class="row text-center flex-row justify-evenly report-button">
		<div class="flex-column flex-size-fill medium-margin-right" title="download">
			<button mat-raised-button class="action-button" color="primary" [disabled]="isDownloading" (click)="download()">
				<mat-icon>download</mat-icon>
			</button>
			<span class="text font-tiny">PDF</span>
		</div>
		<div class="flex-column flex-size-fill medium-margin-right" title="close">
			<button mat-raised-button color="primary" class="action-button" [disabled]="isDownloading" (click)="close()">
				<mat-icon>close</mat-icon>
			</button>
			<span class="text font-tiny">Close</span>
		</div>
	</div>
	<div class="row flex-row gap-small page-actions">
		<mat-form-field compact class="flex-size-fill" [appearance]="'outline'">
			<mat-label>Page Size</mat-label>
			<mat-select [(ngModel)]="selectedPageSize">
				<mat-option *ngFor="let pageSize of pageSizes" [value]="pageSize">
					{{pageSize}}
				</mat-option>
			</mat-select>
		</mat-form-field>
		<mat-form-field compact class="flex-size-fill" [appearance]="'outline'">
			<mat-label>Orientation</mat-label>
			<mat-select [(ngModel)]="orientation">
				<mat-option [value]="'landscape'">
					Landscape
				</mat-option>
				<mat-option [value]="'portrait'">
					Portrait
				</mat-option>
			</mat-select>
		</mat-form-field>
		<mat-form-field class="compact flex-size-fill" [appearance]="'outline'">
			<mat-label>Zoom</mat-label>
			<mat-select [(ngModel)]="zoom">
				<mat-option *ngFor="let option of zoomOptions" [value]="option[1]">
					{{option[0]}}
				</mat-option>
			</mat-select>
		</mat-form-field>
	</div>

	<mat-divider class="row"></mat-divider>

	<div>
		<ng-content select="[controls]"></ng-content>
	</div>
	<mat-spinner *ngIf="isDownloading" matTooltip="Generating PDF"></mat-spinner>
</div>

<report-page-container
	#pageContainer
	[selectedPageSize]="selectedPageSize"
	[orientation]="orientation"
	[zoom]="zoom"
>
	<ng-content select="report-page, report-pages, .report-page">
	</ng-content>
</report-page-container>