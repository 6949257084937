import { Component } from '@angular/core';

@Component({
	selector: 'app-knowledge-article',
	templateUrl: './knowledge-article.component.html',
	styleUrls: ['./knowledge-article.component.scss']
})
export class KnowledgeArticleComponent {
	videos = [
		{ id: 'fNvU8_5glAo', title: 'article_1' }
	];

	openVideo(videoId: string) {
		window.open(`https://www.youtube.com/watch?v=${videoId}`).focus();
	}
}
