import { Component, Input } from '@angular/core';
import * as Highcharts from 'highcharts/highstock';
import * as charts from '@utilities/highchart-objectify';
import { ChartDataService } from '@app/backend';

@Component({
	selector: 'exported-graphs',
	templateUrl: './exported-graphs.component.html',
	styleUrls: ['./exported-graphs.component.scss']
})
export class ExportedGraphsComponent {
	public Highcharts: typeof Highcharts = Highcharts; // required
	public chartOptions = [];
	@Input() chartOption;

	constructor(
		private chartDataService: ChartDataService,
	){}
	
	ngOnInit(){
		this.chartDataService.exportedGraphData$.subscribe(data=>{
			this.chartOptions = charts.generateChartOptions(data);
			if (this.chartOptions?.length > 0) {
				this.chartOptions.map(data => {
					data.title = {
						...data.title,
						style: {
							fontSize: '30px'
						}
					}
					data.subtitle.style.fontSize = '20px';
				})
			}
		})
	}
}
