import { math } from '@lib/math';
import * as pdfkit from './pdf';
import { ParsedColor,Stroke,roundedCornerToBezierCurve } from './shared';

export class PdfRenderer{
	public constructor(
		private readonly doc:pdfkit.PDFDocument
	){
	}

	private transformStack:DOMMatrix[][]=[[new DOMMatrix]];
	private readonly cur=new math.Vec2();
	private manualTransform=new DOMMatrix();

	private transformPoint(p:math.Vec2Like){
		const {x,y}=this.manualTransform.transformPoint(p);
		return new math.Vec2(x,y);
	}

	private moveTo(p:math.Vec2Like){
		this.cur.copy(p);
		p=this.transformPoint(p);
		this.doc.moveTo(p.x,p.y);
	}

	private lineTo(p:math.Vec2Like){
		this.cur.copy(p);
		p=this.transformPoint(p);
		this.doc.lineTo(p.x,p.y);
	}

	public bezierCurveTo(cp1:math.Vec2Like, cp2:math.Vec2Like, p:math.Vec2Like){
		this.cur.copy(p);
		cp1=this.transformPoint(cp1);
		cp2=this.transformPoint(cp2);
		p=this.transformPoint(p);
		this.doc.bezierCurveTo(cp1.x,cp1.y,cp2.x,cp2.y,p.x,p.y);
	}

	public quadraticCurveTo(cp:math.Vec2Like, p:math.Vec2Like){
		this.cur.copy(p);
		cp=this.transformPoint(cp);
		p=this.transformPoint(p);
		this.doc.quadraticCurveTo(cp.x,cp.y,p.x,p.y);
		return this;
	}

	public roundedCornerTo(p:math.Vec2){
		const points=roundedCornerToBezierCurve(this.cur,p);
		if(points)
			this.bezierCurveTo(points[0],points[1],points[2]);
	}

	private closePath(){
		this.doc.closePath();
		return this;
	}

	private applyFill(fill:ParsedColor){
		if(fill.opacity<=0)
			return false;
		const {doc}=this;
		doc.fillColor(fill.rgb);
		doc.fillOpacity(fill.opacity);
		return true;
	}

	private lineCap:string;
	private lineWidth:number;
	private lineDash:string;

	private setDash(dash:number[]){
		if(!dash || dash.length<2 || dash[0]<=0)
			dash=null;
		const dashStr=JSON.stringify(dash);
		if(this.lineDash!==dashStr){
			this.lineDash=dashStr;
			if(dash)
				this.doc.dash(dash[0],{space: dash[1]});
			else
				this.doc.undash();
		}
	}

	private applyStroke(stroke:Stroke){
		if(!stroke.drawable())
			return false;
		const {doc}=this;

		this.setDash(stroke.dash);
		if(this.lineCap!==stroke.lineCap){
			this.lineCap=stroke.lineCap;
			doc.lineCap(stroke.lineCap);
		}
		if(this.lineWidth!==stroke.width){
			this.lineWidth=stroke.width;
			doc.lineWidth(stroke.width);
		}
		doc.strokeColor(stroke.color.rgb,stroke.color.opacity);
		return true;
	}

	private path(path:PdfRenderer.Path){
		const {doc}=this;
		if(typeof(path)==='string'){
			doc.path(path);
			return true;
		}
		if(Array.isArray(path)){
			if(path.length<2)
				return false;
			let i=0;
			for(const p of path){
				if(i===0)
					this.moveTo(p);
				else
					this.lineTo(p);
				++i;
			}
			return true;
		}
		if(path instanceof math.Box2){
			doc.rect(path.min.x,path.min.y,path.size('x'),path.size('y'));
			return true;
		}
		if(path && 'rect' in path){
			const rect=path.rect;
			const size=rect.size();
			const r=path.cornerRadii.slice();
			if(r[0].x+r[1].x>size.x){
				const f=size.x/(r[0].x+r[1].x);
				r[0].x*=f;
				r[1].x*=f;
			}
			if(r[2].x+r[3].x>size.x){
				const f=size.x/(r[2].x+r[3].x);
				r[2].x*=f;
				r[3].x*=f;
			}
			if(r[0].y+r[3].y>size.y){
				const f=size.y/(r[0].y+r[3].y);
				r[0].y*=f;
				r[3].y*=f;
			}
			if(r[1].y+r[2].y>size.y){
				const f=size.y/(r[1].y+r[2].y);
				r[1].y*=f;
				r[2].y*=f;
			}
			const points=[
				rect.atCorner(0).translate(      0,+r[0].y),
				rect.atCorner(0).translate(+r[0].x,   0),
				rect.atCorner(1).translate(-r[1].x,   0),
				rect.atCorner(1).translate(      0,+r[1].y),
				rect.atCorner(2).translate(      0,-r[2].y),
				rect.atCorner(2).translate(-r[2].x,   0),
				rect.atCorner(3).translate(+r[3].x,   0),
				rect.atCorner(3).translate(      0,-r[3].y),
			];
	
			this.moveTo(points[0]);
			this.roundedCornerTo(points[1]);
			this.lineTo(points[2]);
			this.roundedCornerTo(points[3]);
			this.lineTo(points[4]);
			this.roundedCornerTo(points[5]);
			this.lineTo(points[6]);
			this.roundedCornerTo(points[7]);
			this.closePath();
			return true;
		}
		if(typeof(path)==='function'){
			path({
				closePath: ()=>this.closePath(),
				lineTo: p=>this.lineTo(p),
				moveTo: p=>this.moveTo(p),
				bezierCurveTo: (cp1,cp2,p)=>this.bezierCurveTo(cp1,cp2,p),
				quadraticCurveTo: (cp,p)=>this.quadraticCurveTo(cp,p),
			});
			return true;
		}
		return false;
	}

	public fill(
		path:PdfRenderer.Path,
		fill:ParsedColor
	){
		if(path && this.applyFill(fill)){
			if(this.path(path))
				this.doc.fill();
		}
		return this;
	}

	public stroke(
		path:PdfRenderer.Path,
		stroke:Stroke,
	){
		if(path && this.applyStroke(stroke)){
			if(this.path(path))
				this.doc.stroke();
		}
	}

	public fillAndStroke(
		path:PdfRenderer.Path,
		fill:ParsedColor,
		stroke:Stroke
	){
		if(path && this.applyFill(fill) && this.applyStroke(stroke)){
			if(this.path(path))
				this.doc.fillAndStroke();
		}
		return this;
	}

	public clip(
		path:PdfRenderer.Path,
		run:()=>void,
	){
		const {doc}=this;
		if(path){
			doc.save();
			this.path(path);
			doc.clip();
			run();
			doc.restore();
		}else{
			run();
		}
	}

	public transform<R>(
		matrix:DOMMatrix,
		run:()=>R,
		manualTransformStop=false,
	):R{
		const {doc}=this;
		if(matrix && !matrix.isIdentity){
			doc.save();
			doc.transform(matrix.a,matrix.b,matrix.c,matrix.d,matrix.e,matrix.f);
			if(manualTransformStop)
				this.transformStack.push([new DOMMatrix()]);
			const transformStack=this.transformStack.at(-1);
			transformStack.push(transformStack.at(-1).multiply(matrix));
			let r=run();
			transformStack.pop();
			if(manualTransformStop)
				this.transformStack.pop();
			doc.restore();
			return r;
		}
		return run();
	}

	
	public runTransformManually<R>(
		run:()=>R,
	){
		const transformStack=this.transformStack.at(-1);
		const m=transformStack.at(-1).inverse();
		const {doc}=this;
		doc.save();
		doc.transform(m.a,m.b,m.c,m.d,m.e,m.f);
		this.manualTransform=transformStack.at(-1);
		let r=run();
		this.manualTransform=new DOMMatrix();
		doc.restore();
		return r;
	}

	public translate<R>(
		x:number,
		y:number,
		run:()=>R,
	):R{
		this.manualTransform=new DOMMatrix().translateSelf(x,y);
		let r=run();
		this.manualTransform=new DOMMatrix();
		return r;
	}


	public drawImage(ele:HTMLImageElement, rect:math.Box2){
		if(ele instanceof HTMLImageElement && !ele.complete)
			return;

		const src=ele.src.toLowerCase();
		let format='image/png';
		let quality=1;
		if(src.endsWith('.jpg') || src.endsWith('.jpeg')){
			format='image/jpeg';
			quality=0.9;
		}

		if(!(ele.naturalWidth>0 && ele.naturalHeight>0))
			return;
	
		const canvas=document.createElement('canvas');
		const sourceSizeFactor=4;
		if(src.endsWith('.svg')){
			canvas.width=rect.size('x')*sourceSizeFactor;
			canvas.height=rect.size('y')*sourceSizeFactor;
		}else{
			canvas.width=Math.min(ele.naturalWidth,rect.size('x')*sourceSizeFactor);
			canvas.height=Math.min(ele.naturalHeight,rect.size('y')*sourceSizeFactor);
		}
		const ctx=canvas.getContext('2d');
		ctx.drawImage(ele,0,0,ele.naturalWidth,ele.naturalHeight,0,0,canvas.width,canvas.height);

		let dataUrl:string;
		try{
			dataUrl=canvas.toDataURL(format,quality);
		}catch(error){
			dataUrl=null;
		}
		if(dataUrl)
			this.doc.image(dataUrl,rect.min.x,rect.min.y,{width: rect.size('x'), height: rect.size('y')});
	}

	public drawCanvas(ele:HTMLCanvasElement, rect:math.Box2, whiteBackground:boolean){
		let format='image/png';
		let quality=1;

		if(whiteBackground){
			format='image/jpeg';
			quality=0.90;

			const whiteBackgroundContainer=document.createElement("canvas");
			whiteBackgroundContainer.height=ele.height;
			whiteBackgroundContainer.width=ele.width;
		
			const ctx=whiteBackgroundContainer.getContext('2d');
			ctx.fillStyle='white';
			ctx.fillRect(0,0,whiteBackgroundContainer.width,whiteBackgroundContainer.height);
			ctx.drawImage(ele, 0, 0);
			ele=whiteBackgroundContainer;
		}
		let dataUrl:string;
		try{
			//dataUrl is way faster than using the blob and converting to an array buffer, I checked
			dataUrl=ele.toDataURL(format,quality);
		}catch(error){
		}
		if(dataUrl)
			this.doc.image(dataUrl,rect.min.x,rect.min.y,{width: rect.size('x'), height: rect.size('y')});
	}
	
	public applyTextStyles(styles:{
		font:{family:string,size:number},
		fill:ParsedColor,
		stroke?:Stroke,
	}){
		if(styles.stroke){
			this.applyStroke(styles.stroke);
		}
		this.doc
		.lineJoin('round')
		.fillOpacity(styles.fill.opacity)
		.fill(styles.fill.rgb)
		.font(styles.font.family)
		.fontSize(math.floor(styles.font.size));
	}

	public widthOfString(str:string){
		return this.doc.widthOfString(str);//options does nothing, I checked --John Lockwood
	}

	public heightOfString(str:string){
		return this.doc.heightOfString(str,{lineBreak:false});
	}

	public drawText(
		text:string,
		x:number,
		y:number,
		baseline:number,
		fill:boolean,
		stroke:boolean,
	){
		this.doc.fragment(
			text,
			x,
			y,
			{
				// lineBreak: false,
				fill,
				stroke,
				baseline,
				// lineGap: 0,
				// width: 10000,
				// height: 10000,
				// continued: false,
			},
		);		
	}
}

export namespace PdfRenderer{
	export interface Pather{
		closePath():void;
		moveTo(p:math.Vec2Like):void;
		bezierCurveTo(cp1:math.Vec2Like, cp2:math.Vec2Like, p:math.Vec2Like):void;
		lineTo(p:math.Vec2Like):void;
		quadraticCurveTo(cp:math.Vec2Like, p:math.Vec2Like):void;
	}
	export type Path=string|(math.Vec2Like[])|math.Box2|{rect:math.Box2,cornerRadii:math.Vec2[]}|((pather:Pather)=>void);

}
