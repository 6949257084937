<div class="page-scroller" (scroll)="onScroll($event)">
	<div class="page-scroller-inner">
		<div class="page-list" [ngClass]="[selectedPageSize,orientation,'zoom-'+scaler.zoom]">
			<ng-content></ng-content>
		</div>
	</div>
</div>
<div class="scroll-buttons">
	<div class="medium-margin-right">
		<button mat-raised-button class="primary action-button" color="primary" (click)="scroll('prev')">
			<mat-icon>chevron_left</mat-icon>
		</button>
	</div>
	<div>
		<button mat-raised-button class="primary action-button" color="primary" (click)="scroll('next')">
			<mat-icon>chevron_right</mat-icon>
		</button>
	</div>
</div>