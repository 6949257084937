<div class="contents">
	<div class="header">
		Knowledge Articles
	</div>
	<div class="video-container flex-row justify-start">
		<div
			class="video-box"
			*ngFor="let video of videos"
			(click)="openVideo(video.id)"
		>
			<img
			[src]="'https://img.youtube.com/vi/' + video.id + '/0.jpg'"
			[alt]="video.title"
			/>
			<div class="overlay flex-row center items-center gap-small">
				<mat-icon>play_arrow</mat-icon>
				<span> Watch on YouTube</span>
			</div>
		</div>
	</div>  
	<div class="btn-close flex-row justify-end">
		<button mat-button [mat-dialog-close]="null" class="cancel-btn">
			Close
		</button>
	</div>
</div>