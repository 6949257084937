import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators, FormArray } from '@angular/forms';
import { SiteService } from '@app/app-state/site.service';
import { Site } from '@app/models/site.model';
import { Subscription } from 'rxjs';
import * as backend from '@app/backend';
import * as currency from '@utilities/currency';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialogRef } from '@angular/material/dialog';
import { UserService } from '@app/app-state/user.service';
import { UnitConversionPipe } from '@app/pipes';
interface Category {
	data: [];  
	[key: string]: string | [];
}
  
@Component({
	selector: 'app-costing-details',
	templateUrl: './costing-details.component.html',
	styleUrls: ['./costing-details.component.scss']
})
export class CostingDetailsComponent implements OnInit, OnDestroy {
	public productForm: FormGroup;
	public siteControl = new FormControl<string | Site>('');
	private sites: Site[];
	public selectedSite: string;
	public isSiteSelected: boolean = true;
	public filteredSites: Site[];
	private selectedSiteId: number;
	private selectedSubDomainId: number;
	private subscriptions: Subscription = new Subscription();
	public isLoading: boolean = true;
	public currencyData = currency.currencies;
	public isAllDataEmpty: boolean;
	public costingData: any;
	public tableData = {
		explosiveCost: {
			value: 'Explosive cost',
			valueUnit: 'weight',
			data: []
		},
		boosterCost: {
			value: 'Booster cost',
			valueUnit: 'weight',
			data: []
		},
		detonatorCost: {
			value: 'Detonator/detonating fuse (DF) cost',
			data: []
		}
	};

	public userUnitMeasurement;

	constructor(
		private readonly siteService: SiteService,
		private fb: FormBuilder,
		private snackBar: MatSnackBar,
		private dialog: MatDialogRef<CostingDetailsComponent>,
		private userService: UserService,
		private unitConversionPipe: UnitConversionPipe
	) {}

	ngOnInit() {
		this.siteControl.valueChanges.subscribe((value: string) => {
			this.updateResults(value);
		});

		this.subscriptions.add(
			this.userService.userUnitMeasurement$.subscribe((measurement: string) => {
				this.userUnitMeasurement = measurement;
			})
		);

		this.subscriptions.add(
			this.siteService.seletedSiteName$.subscribe((siteName: string) => {
				this.selectedSite = siteName;
				if (this.selectedSite) {
					this.siteControl.setValue(this.selectedSite);
					this.isSiteSelected = true;
				}
			})
		);

		this.subscriptions.add(
			this.siteService.sites$.subscribe(sites => {
				this.sites = sites;
			})
		);

		this.subscriptions.add(
			this.siteService.subDomainId$.subscribe(selectedSubDomainId => {
				if (selectedSubDomainId) {
					this.selectedSubDomainId = selectedSubDomainId;
					this.getBlastProducts(selectedSubDomainId);
				}
			})
		);
		
		this.subscriptions.add(
			this.siteService.siteId$.subscribe(siteId => {
				this.selectedSiteId = siteId;
			})
		);
	}

	public onSiteInput(event) {
		if (!event.target.value) {
			this.isSiteSelected = false;
		}
	}

	public onSiteSelect(event) {
		event.option?.value ? (this.isSiteSelected = true) : (this.isSiteSelected = false);
		this.sites.map(site => {
			if (site?.id === event.option?.value?.id) {
				this.selectedSubDomainId = site?.subdomainId;
				this.selectedSite = site?.name;
				this.selectedSiteId = site?.id;
				this.getBlastProducts(this.selectedSubDomainId);
				this.siteControl.setValue(this.selectedSite);
			}
		});
	}

	private updateResults(value) {
		value = value?.name ? value.name : value;
		const searchValue = value?.toLowerCase().trim();
		this.filteredSites = this.sites?.filter(project => {
			return project.name.toLowerCase().includes(searchValue);
		});
	}

	public productSelect(index: number, productName: string) {
		const id = this.tableData[productName].data[index].id;
		const formArray = this.productForm.get(productName) as FormArray;
		formArray.at(index).patchValue({ id });
		formArray.at(index).patchValue({product:''});
	}

	private async getBlastProducts(subdomainId) {
		this.isLoading = true;
		const response = await backend.getBlastProducts(subdomainId);
		this.costingData = await backend.fetchCostDetail(this.selectedSiteId);
		this.isLoading = false;
		if (response) {
			this.insertDataIntoTable(response);
			
			this.isAllDataEmpty = this.allDataEmpty(this.tableData);
			if (this.costingData?.explosiveCost?.data?.length > 0) {
				this.initializeFormWithOldValue();
			}
			else {
				this.initializeFormWithNewValue();
			}
		}
	}

	private insertDataIntoTable(originalData) {
		this.tableData.explosiveCost.data = originalData.explosiveProducts.map(product => ({
			id: product.id,
			name: product.name,
			brand: product.brand,
			maxDensity: product.maxDensity,
			minDensity: product.minDensity
		}));
	
		this.tableData.boosterCost.data = originalData.boosterProducts.map(product => ({
			id: product.id,
			name: product.name,
			brand: product.brand,
			mass: product.mass
		}));
	
		this.tableData.detonatorCost.data = originalData.detonatorProducts.map(product => ({
			id: product.id,
			name: product.name,
			brand: product.brand,
			electronic: product.electronic,
			delay: product.delay,
			lengths: product.lengths
		}));
	}

	public async saveCostingData() {
		const formValue = this.productForm.value;
	
		const removeEmptyRows = (rows) =>
			rows.filter(row => row.id !== null && row.id !== undefined);
	
		let costingData = {
			costingDetails: {
				explosiveCost: { data: removeEmptyRows(formValue.explosiveCost) },
				boosterCost: { data: removeEmptyRows(formValue.boosterCost) },
				detonatorCost: { data: removeEmptyRows(formValue.detonatorCost) },
				drillingCost: formValue.drillingCost,
				secondaryBreakageCost: formValue.secondaryBreakageCost,
				crusherPowerConsumptionCost: formValue.crusherPowerConsumptionCost,
				apiV2SiteId: this.selectedSiteId
			}
		
		};
		costingData.costingDetails = this.addValueUnitToCostingData(costingData.costingDetails);
		if (this.userUnitMeasurement == 'imperial') {
			costingData.costingDetails = this.convertCostingData(costingData.costingDetails, 'imperial',false, true);
		}
		
		const response = await backend.createCosting(costingData);
		if (response) {
			this.snackBar.open(response.message, ' ', {
				horizontalPosition: 'right',
				verticalPosition: 'top',
				duration: 3000
			});
			this.dialog.close();
		}
	}
	

	public getFilterValues(data, productName, index?) {
		if (this.productForm.value[productName][index]?.brand) {
			return data.filter(
				product => product.brand === this.productForm.value[productName][index]?.brand
			);
		} else {
			return data;
		}
	}

	public getUniqueValues(data) {
		const uniqueValues = new Set();
		return data.filter(item => {
			const value = item['brand'] as string;
			if (uniqueValues.has(value)) {
				return false;
			}
			uniqueValues.add(value);
			return true;
		});
	}

	ngOnDestroy() {
		this.subscriptions.unsubscribe();
	}

	
	private initializeFormWithOldValue() {
		if (this.userUnitMeasurement == 'imperial') {
			this.costingData = this.addValueUnitToCostingData(this.costingData);
			this.costingData  = this.convertCostingData(this.costingData, 'imperial', true, false);
		}
		this.productForm = this.fb.group({
			explosiveCost: this.fb.array([
				...(this.costingData?.explosiveCost?.data?.map((costProduct) =>
					this.createCostGroup(costProduct)
				) || [])
			]),
			boosterCost: this.fb.array([
				...(this.costingData?.boosterCost?.data?.map((costProduct) =>
					this.createCostGroup(costProduct)
				) || [])
			]),
			detonatorCost: this.fb.array([
				...(this.costingData?.detonatorCost?.data?.map((costProduct) =>
					this.createCostGroup(costProduct)
				) || [])
			]),
			drillingCost: this.fb.group({
				cost: [this.costingData?.drillingCost?.cost || ''],
				currency: [this.costingData?.drillingCost?.currency || ''],
			}),
			secondaryBreakageCost: this.fb.group({
				cost: [this.costingData?.secondaryBreakageCost?.cost || ''],
				currency: [this.costingData?.secondaryBreakageCost?.currency || ''],
			}),
			crusherPowerConsumptionCost: this.fb.group({
				cost: [this.costingData?.crusherPowerConsumptionCost?.cost || ''],
				currency: [this.costingData?.crusherPowerConsumptionCost?.currency || ''],
			}),
		});
	}

	private convertCostingData(costingData, unitType: string, isConvertToMetric: boolean, isSavingData: boolean) {
		const costKeys = [
			'explosiveCost', 
			'boosterCost', 
			'detonatorCost', 
			'drillingCost', 
			'secondaryBreakageCost', 
			'crusherPowerConsumptionCost'
		];
	  
		costKeys.forEach(key => {
			if (costingData[key]) {
				const valueUnit = costingData[key]?.valueUnit;
				if (costingData[key]?.data?.length > 0) {
					costingData[key].data?.map(value=>{
						value.cost = Number(this.unitConversionPipe.transform(Number(value.cost), unitType, valueUnit,isConvertToMetric).toFixed(2));
					})
				}
				if(costingData[key]?.cost){
					const cost = costingData[key].cost
					costingData[key].cost = Number(this.unitConversionPipe.transform(Number(cost),unitType, valueUnit,isSavingData).toFixed(2));
				}
			}
		});
		return costingData;
	
	}
	
	private initializeFormWithNewValue() {
		this.productForm = this.fb.group({
			explosiveCost: this.fb.array([this.createCostWithNewValues(this.tableData.explosiveCost)]),
			boosterCost: this.fb.array([this.createCostWithNewValues(this.tableData.boosterCost)]),
			detonatorCost: this.fb.array([this.createCostWithNewValues(this.tableData.detonatorCost)]),
			drillingCost: this.fb.group({
				cost: [''],
				currency: []
			}),
			secondaryBreakageCost: this.fb.group({
				cost: [''],
				currency: []
			}),
			crusherPowerConsumptionCost: this.fb.group({
				cost: [''],
				currency: ['']
			})
		});
	}
	private createCostWithNewValues(costData): FormGroup {
		return this.fb.group({
			brand: ['', costData?.data?.length ? Validators.required : []],
			product: ['', costData?.data?.length ? Validators.required : []],
			currency: ['', costData?.data?.length ? Validators.required : []],
			cost: [null, costData?.data?.length ? [Validators.required, Validators.min(0)] : []],
			id: [null]
		});
	}

	
	private createCostGroup(costData): FormGroup {
		return this.fb.group({
			brand: [costData?.brand ?? '', Validators.required],
			product: [costData?.product ?? '', Validators.required],
			currency: [costData?.currency ?? '', Validators.required],
			cost: [costData?.cost ?? null, [Validators.required, Validators.min(0)]],
			id: [costData?.id ?? null]
		});
	}

	
	private allDataEmpty(tableData): boolean {
		return !Object.values(tableData).some((category: Category) => category.data.length > 0);
	}

	
	public addRow(category: string): void {
		const formArray = this.productForm.get(category) as FormArray;
		formArray.push(this.createCostGroup(this.tableData[category]));
	}

	
	public removeRow(category: string, index: number): void {
		const formArray = this.productForm.get(category) as FormArray;
		if (formArray.length > 1) {
			formArray.removeAt(index);
		}
	}

	
	get explosiveCostRows(): FormArray {
		return this.productForm.get('explosiveCost') as FormArray;
	}

	get boosterCostRows(): FormArray {
		return this.productForm.get('boosterCost') as FormArray;
	}

	get detonatorCostRows(): FormArray {
		return this.productForm.get('detonatorCost') as FormArray;
	}

	private addValueUnitToCostingData(costingData) {
		const unitMappings = {
			explosiveCost: 'weight',
			boosterCost: 'weight',
			detonatorCost: 'count',
			drillingCost: 'distance',
			secondaryBreakageCost: 'count',
			crusherPowerConsumptionCost: 'count'
		};
		
		Object.keys(unitMappings).forEach(key => {
			if (costingData[key]) {
				costingData[key].valueUnit = unitMappings[key];
				if(costingData[key]?.data?.length > 0){
					costingData[key]?.data?.map(data=>{
						data.cost = Number(data.cost);
					})
					
				}
				if (costingData[key]?.cost) {
					costingData[key].cost = Number(costingData[key]?.cost);
				}
			}
		});
		
		return costingData;
	}
}
