<div class="left">
	<mat-icon svgIcon="brandLogo"></mat-icon>
	<span>{{vendor}},&nbsp;</span>
	<span class="created-at">Created at {{date|date:"MM/dd/yyyy"}}</span>
</div>

<div class="report-name">
	<ng-content></ng-content>
</div>

<div class="page-number">
	&nbsp;
</div>
