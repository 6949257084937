import { Component, Input } from '@angular/core';

@Component({
	selector: 'report-pages',
	templateUrl: './report-pages.component.html',
	styleUrls: ['./report-pages.component.scss']
})
export class ReportPagesComponent {
	@Input() footer=true;
	@Input() orientation:"landscape"|"portrait"|""="";
}
