<report (closeModal)="closeModal($event)">
	<report-page-title ngProjectAs="report-page"
		title = "{{title + ' Report'}}"
	>
	</report-page-title>
	<exported-graphs
		*ngFor="let chart of chartOptions"
		ngProjectAs="report-page"
		[chartOption]="chart"
	>
	</exported-graphs>
</report>