<mat-tab-group (selectedTabChange)="onSequenceChangeEvent($event)">
	<mat-tab label="PF & Tons" >
		<ng-template matTabContent>
			<app-pf-and-tons></app-pf-and-tons>
		</ng-template>
	</mat-tab>
	<mat-tab label="Fragmentation">
		<ng-template matTabContent>
			<app-fragmentation></app-fragmentation>
		</ng-template>
	</mat-tab>
	<mat-tab label="Muckpile">
		<ng-template matTabContent>
			<app-muckpile></app-muckpile>
		</ng-template>
	</mat-tab>
	<mat-tab label="Fragmentation Table View">
		<ng-template matTabContent>
			<app-fragmentation-table-view></app-fragmentation-table-view>
		</ng-template>
	</mat-tab>
</mat-tab-group>