import { Component, OnInit } from '@angular/core';
import { User } from '@app/models/user.model';
import { UserService } from '@app/app-state/user.service';
import { MatDialog } from '@angular/material/dialog';

import { UnitSettingsDialog } from "@app/gui/components/unit-settings-dialog/unit-settings-dialog.component";
import { CostingDetailsComponent } from '../costing-details/costing-details.component';
import { SiteService } from '@app/app-state/site.service';
import { KnowledgeArticleComponent } from '../knowledge-article/knowledge-article.component';

@Component({
	selector: 'app-header',
	templateUrl: './header.component.html',
	styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit{
	public user:User;
	public isSiteSelected: boolean = false;
	constructor(
		public readonly userService:UserService,
		private readonly dialog: MatDialog,
		private siteService: SiteService
	) {
	}

	ngOnInit(): void {
		this.user = this.userService.user;
		this.siteService.siteId$.subscribe(id => {
			id ? this.isSiteSelected = true : this.isSiteSelected = false;
		})
	}

	public logout() {
		this.userService.logout();
	}

	public unitSettings() {
		this.dialog.open(UnitSettingsDialog, {
				width: '600px', 
				disableClose: true, 
		});
	}

	public openCostingDialog() {
		this.dialog.open(CostingDetailsComponent, {
			width: '100vw', 
			height: '100vh',
			maxWidth: '100vw',
			panelClass: 'full-screen-dialog',
			disableClose: true, 
		});
	}

	public openArticles() {
		this.dialog.open(KnowledgeArticleComponent, {
			width: '100vw', 
			height: '100vh',
			maxWidth: '100vw',
			panelClass: 'full-screen-dialog',
			disableClose: true, 
		});
	}
}
