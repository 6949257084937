//A recreation of limited functionality of node 'path' for browser use

export namespace path{
	export function extname(path:string){
		const i=path.lastIndexOf('.');
		if(i>0)
			return path.substring(i);
		return '';
	}
}
