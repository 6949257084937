import { math } from "@lib/math";
import { ParsedColor,Stroke,fromColor,fromPx,fromPxOrPercent } from "./shared";

export class ElementMeta{
	public constructor(
		private readonly element:HTMLElement|SVGSVGElement
	){
	}

	public styles:CSSStyleDeclaration&{zoom?:string};
	public zIndex:number;
	public originalTransform?:string;
	public rect:math.Box2=null;

	public outsideRect(container:math.Box2){
		const {rect}=this;

		return (
			rect.max.y<container.min.y
			|| rect.min.x>container.max.x
			|| rect.min.y>container.max.y
			|| rect.max.x<container.min.x
		);
	}

	public getTransformMatrix(){
		const {rect,styles}=this;
		let transform=new DOMMatrix(styles.transform);
		if(transform.isIdentity)
			return null;
		const transformOrigin=styles.transformOrigin.split(' ').map(fromPx);
		let matrix=new DOMMatrix().translateSelf(-rect.min.x-transformOrigin[0],-rect.min.y-transformOrigin[1]);
		matrix=matrix.preMultiplySelf(transform);
		matrix=matrix.preMultiplySelf(new DOMMatrix().translateSelf(transformOrigin[0],transformOrigin[1]));
		matrix=matrix.preMultiplySelf(new DOMMatrix().translateSelf(rect.min.x,rect.min.y));
		return matrix;
	}

	private getBorders(){
		const {styles}=this;
		const borders=[
			new ElementMeta.Border(),
			new ElementMeta.Border(),
			new ElementMeta.Border(),
			new ElementMeta.Border(),
		];

		borders[0].color=fromColor(styles.borderTopColor);
		borders[1].color=fromColor(styles.borderRightColor);
		borders[2].color=fromColor(styles.borderBottomColor);
		borders[3].color=fromColor(styles.borderLeftColor);

		borders[0].width=fromPx(styles.borderTopWidth);
		borders[1].width=fromPx(styles.borderRightWidth);
		borders[2].width=fromPx(styles.borderBottomWidth);
		borders[3].width=fromPx(styles.borderLeftWidth);

		if(this.element instanceof HTMLTableCellElement && styles.borderCollapse==='collapse'){
			const cell=this.element;
			if(cell.nextElementSibling instanceof HTMLTableCellElement){
				const cellNext=cell.nextElementSibling;
				if(getComputedStyle(cellNext).borderLeftWidth!=='0px')
					borders[1].width=0;
			}
	
			let tr=<HTMLTableRowElement>cell.parentElement;
			if(tr instanceof HTMLTableRowElement){
				if(tr.nextElementSibling instanceof HTMLTableRowElement){
					tr=tr.nextElementSibling;
					const cellBelow=tr.cells.item(cell.cellIndex);
					if(cellBelow && styles.borderTopWidth!=='0px')
						borders[2].width=0;
				}else{
					let tbody=tr.parentElement;
					if((tbody.tagName==='THEAD' || tbody.tagName==='TBODY') && (tbody.nextElementSibling?.tagName==='THEAD' || tbody.nextElementSibling?.tagName==='TBODY'))
						borders[2].width=0;
				}
			}
		}
		return borders;
	}

	public getRects():ElementMeta.BoxInfo{
		const {rect,styles}=this;
		const borderRect=rect;
		const borders=this.getBorders();

		const clientRect=borderRect.clone();
		clientRect.min.y+=borders[0].width;
		clientRect.max.x-=borders[1].width;
		clientRect.max.y-=borders[2].width;
		clientRect.min.x+=borders[3].width;

		const padding={
			top: fromPx(styles.paddingTop),
			right: fromPx(styles.paddingRight),
			bottom: fromPx(styles.paddingBottom),
			left: fromPx(styles.paddingLeft),
		};

		const paddingRect=clientRect.clone();
		paddingRect.min.x+=padding.left;
		paddingRect.min.y+=padding.top;
		paddingRect.max.x-=padding.right;
		paddingRect.max.y-=padding.bottom;
		
		const borderRadii:math.Vec2[]=[
			new math.Vec2(
				fromPxOrPercent(styles.borderTopLeftRadius,borderRect.size('x')),
				fromPxOrPercent(styles.borderTopLeftRadius,borderRect.size('y')),
			),
			new math.Vec2(
				fromPxOrPercent(styles.borderTopRightRadius,borderRect.size('x')),
				fromPxOrPercent(styles.borderTopRightRadius,borderRect.size('y')),
			),
			new math.Vec2(
				fromPxOrPercent(styles.borderBottomRightRadius,borderRect.size('x')),
				fromPxOrPercent(styles.borderBottomRightRadius,borderRect.size('y')),
			),
			new math.Vec2(
				fromPxOrPercent(styles.borderBottomLeftRadius,borderRect.size('x')),
				fromPxOrPercent(styles.borderBottomLeftRadius,borderRect.size('y')),
			),
		];

		if(borderRadii[0].x>borderRadii[1].x)
			borderRadii[0].x=borderRadii[1].x=(borderRadii[0].x+borderRadii[1].x)/2;
		if(borderRadii[3].x>borderRadii[2].x)
			borderRadii[3].x=borderRadii[3].x=(borderRadii[3].x+borderRadii[2].x)/2;
		if(borderRadii[1].y>borderRadii[2].y)
			borderRadii[1].y=borderRadii[2].y=(borderRadii[1].y+borderRadii[2].y)/2;
		if(borderRadii[0].y>borderRadii[3].y)
			borderRadii[0].y=borderRadii[3].y=(borderRadii[0].y+borderRadii[3].y)/2;

		const backgroundColor=fromColor(styles.backgroundColor);

		if(this.element instanceof HTMLTableCellElement && styles.borderCollapse==='collapse'){
			const cell=this.element;
			if(cell.nextElementSibling instanceof HTMLTableCellElement){
				const cellNext=cell.nextElementSibling;
				if(getComputedStyle(cellNext).borderLeftWidth!=='0px')
					borders[1].width=0;
			}
	
			let tr=<HTMLTableRowElement>cell.parentElement;
			if(tr instanceof HTMLTableRowElement){
				if(tr.nextElementSibling instanceof HTMLTableRowElement){
					tr=tr.nextElementSibling;
					const cellBelow=tr.cells.item(cell.cellIndex);
					if(cellBelow && styles.borderTopWidth!=='0px')
						borders[2].width=0;
				}else{
					let tbody=tr.parentElement;
					if((tbody.tagName==='THEAD' || tbody.tagName==='TBODY') && (tbody.nextElementSibling?.tagName==='THEAD' || tbody.nextElementSibling?.tagName==='TBODY'))
						borders[2].width=0;
				}
			}
		}

		return {
			borderRect,
			clientRect,
			paddingRect,
			// borderPoints,
			// clientPoints,
			borders,
			backgroundColor,
			borderRadii,
		}
	}

	public isPartOfTextFlow(){
		const {styles}=this;
		return styles.display==='inline' && (styles.position==='static' || styles.position==='relative');
	}
}

export namespace ElementMeta{

	export function pull(ele:HTMLElement|SVGSVGElement, zIndex:number){
		if(ele.classList.contains('no-pdf'))
			return null;
		const meta=new ElementMeta(ele);
		meta.styles=getComputedStyle(ele);
		if(meta.styles.visibility==='hidden')
			return null;

		if(meta.styles.zIndex!=='auto')
			zIndex=zIndex+(+meta.styles.zIndex);
		meta.zIndex=zIndex;
		return meta;
	}

	export class Border extends Stroke{
		public constructor(){
			super();
			this.lineCap='square';
		}

		points:math.Vec2[];
	}

	export interface BoxInfo{
		borderRect:math.Box2;
		clientRect:math.Box2;
		paddingRect:math.Box2;
		borders:Border[];
		borderRadii:math.Vec2[];
		backgroundColor:ParsedColor;
	}
}

