<div class="reports">
	<report-pages [title]="chartOption.title?.text + ' Report'">
		<report-header></report-header>
		<div class="flex-column full-height">
			<highcharts-chart
				class="graph"
				[Highcharts]="Highcharts"
				[options]="chartOption">
			</highcharts-chart>
		</div>
		<table class="data">
			<thead>
				<tr>
					<th>Dataset Name</th>
					<th *ngFor="let series of chartOption.series">{{ series.name }}</th>
				</tr>
			</thead>
			<tbody>
				<tr *ngFor="let dataset of chartOption.xAxis.categories; let i = index">
					<td>{{ dataset }}</td>
					<td *ngFor="let series of chartOption.series">{{ series.data[i] }}</td>
				</tr>
			</tbody>
		</table>
	</report-pages>
</div>