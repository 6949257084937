<div #contentWindow class="content-window">
	<div class="content">
		<ng-content></ng-content>
	</div>
</div>
<ng-content *ngIf="!footer" select=".report-custom-footer"></ng-content>
<report-page-footer *ngIf="footer"></report-page-footer>
<button mat-mini-fab class="no-pdf page-close small-padding" (click)="closed=true">
	<mat-icon>close</mat-icon>
</button>
