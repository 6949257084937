import { math } from "@lib/math";

export const sides=[
	'top',
	'right',
	'bottom',
	'left',
];

export interface Sides{
	top:number;
	right:number;
	bottom:number;
	left:number;
}

export interface ParsedColor{
	rgb:[number,number,number];
	opacity:number;
}

export class Stroke{
	color:ParsedColor;
	width=0;
	dash:number[]=null;
	lineCap='butt';
	nonScalingStroke=false;

	public drawable(){
		return this.width>0 && this.color.opacity>0;
	}

	public equals(that:Stroke){
		if(!this.drawable() && !that.drawable())
			return true;
		if(this.color.rgb.join()!==that.color.rgb.join())
			return false;
		return this.color.opacity===that.color.opacity && this.width===that.width && this.lineCap===that.lineCap && this.nonScalingStroke===that.nonScalingStroke;
	}
}

export function fromColor(string:string):ParsedColor{
	if(string==='none')
		return {rgb: (<[number,number,number]>[0,0,0]), opacity: 0};
	else if(string.startsWith('rgb('))
		string=string.substring(4,string.length-1);
	else if(string.startsWith('rgba('))
		string=string.substring(5,string.length-1);
	else
		return {rgb: (<[number,number,number]>[0,0,0]), opacity: 0};
	let color=string.split(',').map(v=>+v.trim());
	let opacity=1;
	if(color.length===4){
		opacity=color[3];
		color.length=3;
	}
	return {rgb:<[number,number,number]>color,opacity};
}

export function fromPx(string:string){
	if(string.endsWith('px'))
		return +string.substring(0,string.length-2);
	if(string.endsWith('px,'))
		return +string.substring(0,string.length-3);
	if(string==='')
		return 0;
	console.warn('returning 0',typeof(string),string);
	return 0;
}

export function fromPxOrPercent(string:string, total:number){
	if(string.endsWith('%'))
		return total*(+string.substring(0,string.length-1));
	if(string.endsWith('px'))
		return +string.substring(0,string.length-2);
	if(string.endsWith('px,'))
		return +string.substring(0,string.length-3);
	if(string==='')
		return 0;
	console.warn('returning 0',typeof(string),string);
	return 0;
}

export function getRectStr(rect:math.Box2) {
	return JSON.stringify({
		x: rect?.min.x,
		y: rect?.min.y,
	});
}

// export function roundedRectPoints(rect:DOMRect, _radii:number[]){
// 	const corners=[
// 		new math.Vec2(rect.left,rect.top),
// 		new math.Vec2(rect.right,rect.top),
// 		new math.Vec2(rect.right,rect.bottom),
// 		new math.Vec2(rect.left,rect.bottom),
// 	];

// 	const radii:number[]=[];
// 	for(let i=0;i<4;++i){
// 		const ra=_radii[i*2];
// 		const rb=_radii[i*2+1];
// 		const sideRadii=ra+rb;
// 		const sideLength=Math.max(0,i%2===0?rect.width:rect.height);
// 		if(sideRadii>sideLength){
// 			radii.push(ra*sideLength/sideRadii);
// 			radii.push(rb*sideLength/sideRadii);
// 		}else{
// 			radii.push(ra);
// 			radii.push(rb);
// 		}
// 	}

// 	const points:math.Vec2[]=[];

// 	for(let i=0;i<4;++i){
// 		const a=corners[i];
// 		const b=corners[(i+1)%4];
// 		const v=b.clone().sub(a).normalize();
// 		const u=new math.Vec2(-v.y,v.x);
// 		const r=[
// 			radii[(i*2+7)%8],
// 			radii[i*2],
// 			radii[i*2+1],
// 			radii[(i*2+2)%8],
// 		];

// 		let q=a.clone();
// 		q.addScaledVector(v,r[1]*(1-math.SQRT1_2));
// 		q.addScaledVector(u,r[0]*(1-math.SQRT1_2));
// 		points.push(q);

// 		q=a.clone();
// 		q.addScaledVector(v,r[1]);
// 		points.push(q);

// 		q=b.clone();
// 		q.addScaledVector(v,-r[2]);
// 		points.push(q);

// 		q=b.clone();
// 		q.addScaledVector(u,r[3]*(1-math.SQRT1_2));
// 		q.addScaledVector(v,r[2]*-(1-math.SQRT1_2));
// 		points.push(q);
// 	}
// 	return points;
// }

const kappa=4.0*((Math.sqrt(2)-1.0)/3.0);//ratio of inner point to outer point to get control point; cntrl=inner.lerp(outer,kapp)

export function roundedCornerToBezierCurve(
	a:math.Vec2,
	b:math.Vec2,
){
	let out:[number,number][];
	if(a.x<b.x){//moving right
		if(a.y>b.y){//moving up
			//top left
			//inner=[b.x,a.y];
			//outer=[a.x,b.y];
			const c=new math.Vec2(math.lerp(b.x,a.x,kappa),math.lerp(a.y,b.y,kappa));
			out=[
				//[a.x,a.y],
				[a.x,c.y],
				[c.x,b.y],
				[b.x,b.y],
			];
		}else if(a.y<b.y){//moving down
			//top right
			//inner=[a.x,b.y];
			//outer=[b.x,a.y];
			const c=new math.Vec2(math.lerp(a.x,b.x,kappa),math.lerp(b.y,a.y,kappa));
			out=[
				//[a.x,a.y],
				[c.x,a.y],
				[b.x,c.y],
				[b.x,b.y],
			];
		}
	}else if(a.x>b.x){//moviing left
		if(a.y<b.y){//moving down
			//bottom right
			//inner=[b.x,a.y];
			//outer=[a.x,b.y];
			const c=new math.Vec2(math.lerp(b.x,a.x,kappa),math.lerp(a.y,b.y,kappa));
			out=[
				//[a.x,a.y],
				[a.x,c.y],
				[c.x,b.y],
				[b.x,b.y],
			];
		}else if(a.y>b.y){//moving up
			//bottom left
			//inner=[a.x,b.y];
			//outer=[b.x,a.y];
			const c=new math.Vec2(math.lerp(a.x,b.x,kappa),math.lerp(b.y,a.y,kappa));
			out=[
				//[a.x,a.y],
				[c.x,a.y],
				[b.x,c.y],
				[b.x,b.y],
			];
		}
	}
	
	if(!out)
		return null;
	return out.map(p=>math.Vec2.fromArray(p));
}
