import { Component, ElementRef, Input, NgZone } from '@angular/core';
import { ReportService } from '../service.service';
import * as htmlToPdf from '@utilities/html-to-pdf';
import { ReportPaginator } from '../paginator';
import { ReportPageScaler } from '../scaler';
import { BehaviorSubject } from '@rxjs';
@Component({
	selector: 'report-page-container',
	templateUrl: './report-page-container.component.html',
	styleUrls: ['./report-page-container.component.scss']
})
export class ReportPageContainerComponent {
  constructor(
		private readonly ngZone:NgZone,
		private readonly host:ElementRef<HTMLElement>,
		private readonly report:ReportService,
	) {}

	@Input()
	public get selectedPageSize(){
		return this._selectedPageSize;
	}

	public set selectedPageSize(v){
		this._selectedPageSize=v;
		this.paginator.reset();
		this.scaler.apply();
	}

	@Input()
	public get orientation(){
		return this.report.orientation;
	}

	public set orientation(v){
		this.report.orientation=v;
		this.paginator.reset();
		this.scaler.apply();
	}

	@Input()
	public get zoom(){
		return this.scaler.zoom;
	}
	
	public set zoom(v){
		if(this.scaler.zoom!==v){
			this.scaler.zoom=v;
			this.scaler.apply();
		}
	}

	private _selectedPageSize='LETTER';
	public readonly scaler=new ReportPageScaler(this.ngZone,this.host);
	private readonly paginator=new ReportPaginator(this.ngZone,this.host);
	public markups:string[];

	private lastSyncedScroll=0

	public ngOnInit(){
		this.initHtmlToPdf();
	}

	public ngAfterViewInit(): void {

		if(self!==top){
			//for syncing scrolling in report comparer
			window.addEventListener('message',evt=>{
				if(evt.data && typeof(evt.data)==='object' && evt.data.type==='report-scroll'){
					if(this.lastSyncedScroll!==evt.data.value){
						this.lastSyncedScroll=evt.data.value;
						this.host.nativeElement.querySelector('div.page-scroller').scrollTo({
							left: evt.data.value,
							behavior: 'instant',
						});
					}
				}
			});
		}
	}

	public ngAfterViewChecked(){
		this.scaler.apply(false);
		this.paginator.start();
	}

	private async initHtmlToPdf(){
		await htmlToPdf.initialize(
			'report-page-sizes',
			[
				'.page-list.${size}.${orientation} report-page{${body}}',
				'.page-list.${size} report-page.${orientation}.${orientation}{${body}}',
				'report .page-list.${size}.${orientation} report-page{${body}}',
				'report .page-list.${size} report-page.${orientation}.${orientation}{${body}}'
			]
		);
	}

	public async pdfBlob(){
		const progress$=new BehaviorSubject<number>(0);
		const pages:HTMLElement[]=[];
		this.host.nativeElement.querySelectorAll<HTMLElement>('report-page').forEach(page=>pages.push(page));
		
		const blob=await htmlToPdf.convert(pages,progress$);
		return blob;
	}

	public onScroll(evt:Event){
		if(self!==top){
			//for syncing scrolling in report comparer
			const scroller=<HTMLElement>evt.target;
			if(this.lastSyncedScroll!==scroller.scrollLeft){
				this.lastSyncedScroll===scroller.scrollLeft;
				top.postMessage({type:'report-scroll',value:scroller.scrollLeft},'*');
			}
		}
	}

	public scroll(direction:'next'|'prev'){
		const hostEle=this.host.nativeElement;
		if(!hostEle)
			return;

		const pages=hostEle.querySelectorAll('report-page');
		const pagesContainer=hostEle.querySelector('.page-scroller');

		let currentPage=0;
		for(let i=0; i<pages.length; ++i){
			const page=pages.item(i);
			if(!(page instanceof HTMLElement && page.style.display!=='none'))
				continue;

			const rect = page.getBoundingClientRect();
			const hostRect = pagesContainer.getBoundingClientRect();
			if(rect.left>=hostRect.left && rect.right-rect.left+hostRect.left<=hostRect.right){
				currentPage=i;
				break;
			}
		}

		const nextPage=direction==='next'?currentPage+1:currentPage-1;

		if(nextPage>=0 && nextPage<pages.length){
			const page=pages.item(nextPage);
			if(page instanceof HTMLElement && page.style.display!=='none'){
				page.scrollIntoView({block:'center', inline:'center', behavior:'smooth'});
			}
		}
	}
}
