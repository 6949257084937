import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { SiteService } from '@app/app-state/site.service';
import { UserService } from '@app/app-state/user.service';
import * as backend from '@app/backend';
import { ChartDataService } from '@app/backend/chart-data.service';
import { GenerateChartDataService } from '@app/backend/generate-chart-data.service';
import { GraphReportComponent } from '@app/gui/report/graph-report/graph-report.component';
import { ChartDataInterface, FragmentationInterface } from "@app/models/chart-data.model";
import * as charts from '@utilities/highchart-objectify';
import * as Highcharts from 'highcharts/highstock';
import { Subscription } from 'rxjs';

@Component({
	selector: 'app-muckpile',
	templateUrl: './muckpile.component.html',
	styleUrls: ['./muckpile.component.scss']
})
export class MuckpileComponent implements OnInit, OnDestroy {

	public Highcharts: typeof Highcharts = Highcharts; // required
	public chartOptions = [] // required
	public loading:boolean;
	
	private subscriptions: Subscription = new Subscription();
	private domainId: number;
	private subdomainId: number;
	private siteId: number;
	private startDate: string;
	private endDate: string;
	private selectedTimeline: string
	private muckpile:ChartDataInterface[] = [];

	constructor(
		private chartDataService:ChartDataService, 
		private generateChartDataService:GenerateChartDataService,
		private siteService: SiteService,
		private userService: UserService,
		private dialog: MatDialog
	) {	}

	ngOnInit(): void {
		this.subscriptions.add(this.chartDataService.loading$.subscribe(loading => {
			this.loading = loading;
		}));

		this.subscriptions.add(this.chartDataService.fragmentationData$.subscribe((res) => {
			this.triggerLoadData(res);
		}));
		
		this.subscriptions.add(this.siteService.domainId$.subscribe(domainId => {
			this.domainId = domainId;
			if (this.domainId) {
				this.getData();;
			}
		}));

		this.subscriptions.add(this.siteService.subDomainId$.subscribe(subdomainId => {
			this.subdomainId = subdomainId;
			this.triggerLoadData();
		}));

		this.subscriptions.add(this.siteService.siteId$.subscribe(siteId => {
			this.siteId = siteId;
			this.triggerLoadData();
		}));

		this.subscriptions.add(this.siteService.startDate$.subscribe(startDate => {
			this.startDate = startDate;
			this.triggerLoadData();
		}));

		this.subscriptions.add(this.siteService.endDate$.subscribe(endDate => {
			this.endDate = endDate;
			this.triggerLoadData();
		}));
		
		this.subscriptions.add(this.siteService.selectedTimeline$.subscribe(selectedTimeline => {
			this.selectedTimeline = selectedTimeline;
			this.triggerLoadData();
		})); 

		this.subscriptions.add(this.userService.userUnitMeasurement$.subscribe(() => {
			this.triggerLoadData();
		}));
	}

	private getData(){
		this.subscriptions.add(this.chartDataService.getCacheFragmentationData().subscribe(details => {
			this.triggerLoadData(details);
		}))
	}

	private loadData = (chartData: ChartDataInterface[])=> {
		this.muckpile = chartData;
		const data:FragmentationInterface = this.generateChartDataService.generateChartData(this.muckpile);
		for (const key in data?.muckpile) {
			this.chartOptions = charts.generateChartOptions(data.muckpile[key]);
		}
	}

	private triggerLoadData(data? : ChartDataInterface[]) {
		const originalFragmentationData = data || this.generateChartDataService.originalFragmentationData;
		this.muckpile = this.generateChartDataService.getFilteredData(
			originalFragmentationData,
			this.domainId,
			this.subdomainId,
			this.siteId,
			this.startDate,
			this.endDate,
			this.selectedTimeline    
		);
		this.loadData(this.muckpile);
	}

	public pdfDownload() {
		this.dialog.open(GraphReportComponent, {
			width: '100vw', 
			height: '100vh',
			maxWidth: '100vw',
			panelClass: 'full-screen-dialog',
			disableClose: true
		});
	}

	ngOnDestroy() {
		this.subscriptions.unsubscribe();
	}
}