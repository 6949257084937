import { math } from "@lib/math";
import { ElementMeta } from "./element-meta";
import { PageMeta } from "./page-meta";
import { PdfRenderer } from "./pdf-renderer";
import { PdfSvgRenderer } from "./pdf-svg-renderer";
import { renderText } from "./render-text";
import { getRectStr } from "./shared";

export class PdfHTMLRenderer{
	public constructor(
		private readonly rdr:PdfRenderer,
		private readonly page:PageMeta,
	){
	}

	private drawDomBox(boxInfo:ElementMeta.BoxInfo){
		const {
			borderRect,
			backgroundColor,
			borders,
			borderRadii,
		}=boxInfo;
	
		const {rdr}=this;
		if(backgroundColor.opacity>0){
			rdr.fill(
				{
					rect: borderRect,
					cornerRadii: borderRadii,
				}
				,backgroundColor
			);
		}
	
		const rect=borderRect.clone();
		rect.min.y+=borders[0].width/2;
		rect.max.x-=borders[1].width/2;
		rect.max.y-=borders[2].width/2;
		rect.min.x+=borders[3].width/2;

		if(borderRadii.every(r=>r.lengthSq()===0)){
			for(let i=0;i<4;++i)
				borders[i].points=[rect.atCorner(i),rect.atCorner(i+1)];
			for(const [a,b] of borders.pairs()){
				if(a.equals(b)){
					a.points.pop();
					b.points=a.points.concat(b.points);
					a.points=[];
				}
			}
			for(const border of borders){
				if(border.drawable())
					rdr.stroke(border.points,border);
			}
		}else{
			if(borders[0].drawable()){
				rdr.stroke(
					{
						rect: rect,
						cornerRadii: borderRadii,
					},				
					borders[0]
				);
			}
		}
	}

	public drawElement(
		containerRect:math.Box2|null,
		ele:HTMLElement|SVGSVGElement,
		depth:number
	){
		const {rdr,page}=this;
		const meta=page.meta.get(ele);
		if(!meta)
			return;
	
		const {styles}=meta;
		if(meta.outsideRect(containerRect))
			return;
	
		const matrix=meta.getTransformMatrix();
		rdr.transform(matrix,()=>{
			const boxInfo=meta.getRects();
		
			if(displayRenderable(ele,styles.display)){
				this.drawDomBox(boxInfo);
				if(ele instanceof HTMLElement)
					renderText(rdr,page,ele,boxInfo.paddingRect);
			}

			let clipBox:PdfRenderer.Path;
			if(styles.overflow==='hidden'){
				containerRect=boxInfo.clientRect;
				clipBox={
					rect: boxInfo.clientRect,
					cornerRadii: boxInfo.borderRadii,
				};
			}

			rdr.clip(clipBox,()=>{
				if(ele instanceof SVGSVGElement){
					this.drawSVG(containerRect,ele,boxInfo.clientRect,depth);
				}else if(ele instanceof HTMLCanvasElement){
					this.drawCanvas(ele,boxInfo.paddingRect);
				}else if(ele instanceof HTMLImageElement){
					rdr.drawImage(ele,boxInfo.clientRect);
				}else{
					for(const child of Array.from(ele.children)){
						if(child instanceof HTMLElement || child instanceof SVGSVGElement)
							this.drawElement(containerRect,child,depth+1);
					}
				}
			});
		});
	}

	private drawSVG(containerRect:math.Box2, ele:SVGSVGElement, clientRect:math.Box2, depth:number){
		const {rdr,page}=this;
		const meta=page.meta.get(ele);
		if(!meta)
			return;
	
		const foreignElements=PdfSvgRenderer.draw(rdr,ele,clientRect);
		for(const ele of foreignElements)
			this.drawElement(containerRect,ele,depth+1);
	}

	private drawCanvas(ele:HTMLCanvasElement, rect:math.Box2){
		const {rdr,page}=this;
		const meta=page.meta.get(ele);
		if(!meta)
			return;
		const rectStr=getRectStr(rect);
		if(page.canvasList.has(rectStr)){
			const canvasList=page.canvasList.get(rectStr);
			if(canvasList.length>1){
				canvasList.forEach((element,index)=>{
					const transparency=index>0;
					rdr.drawCanvas(element,rect,!transparency);
					page.meta.delete(element);
				});
			}else{
				rdr.drawCanvas(ele,rect,true);
			}
		}else{
			rdr.drawCanvas(ele,rect,true);
		}
	}
	
}


function displayRenderable(
	ele:HTMLElement|SVGSVGElement,
	display:string
){
	if(ele instanceof SVGSVGElement && display==='inline')
		return true;
	return display==='block'
	|| display==='inline-block'
	|| display==='flex'
	|| display==='grid'
	|| display==='table'
	|| display==='table-column-group'
	|| display==='table-header-group'
	|| display==='table-footer-group'
	|| display==='table-row'
	|| display==='table-column'
	|| display==='table-cell';
}