import { Component, ElementRef, HostBinding, Input, ViewChild } from '@angular/core';

@Component({
	selector: 'report-page',
	templateUrl: './report-page.component.html',
	styleUrls: ['./report-page.component.scss']
})
export class ReportPageComponent {
	@ViewChild('contentWindow') contentWindow:ElementRef<HTMLElement>;

	@Input() footer=true;

	@HostBinding('style.display') get styleDisplay(){
		return this.closed?'none':'';
	}

	public closed=false;
}
