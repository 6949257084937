import { SvgPath } from '@lib/dom/rendering-context/svg-path';
import { math } from '@lib/math';
import { getBaseline,getFont } from './fonts';
import { PdfRenderer } from './pdf-renderer';
import { ParsedColor,Stroke,fromColor,fromPx } from './shared';

const KAPPA = 4.0*((Math.sqrt(2)-1.0) / 3.0);

function attrToNum(a:SVGAnimatedLength){
	return a.baseVal.value;
}

function attrToPoint(x:SVGAnimatedLength, y:SVGAnimatedLength){
	return new math.Vec2(x.baseVal.value,y.baseVal.value);
}

const alignmentBaselineToBaseline:Record<string,string>={
	auto: 'baseline',
	baseline: 'baseline',
	'before-edge': undefined,
	'text-bottom': undefined,
	'text-before-edge': undefined,
	middle: 'middle',
	central: 'middle',
	'after-edge': undefined,
	'text-top': undefined,
	'text-after-edge': undefined,
	'ideographic': undefined,
	'alphabetic': 'baseline',
	'mathematical': undefined,
	hanging: 'top',
	//top: 'top',
	center: 'middle',
	//bottom: 'bottom',
}

type Fill=ParsedColor|SVGPatternElement;

interface RenderProps{
	fill:Fill;
	stroke:Stroke;
	paintOrder:('fill'|'stroke')[];
	clipPath:string;
	markerStart:SVGMarkerElement;
	markerEnd:SVGMarkerElement;
}

function getEleFromUrl<T extends typeof SVGElement>(ele:SVGElement, url:string, type:T):InstanceType<T>{
	if(url.startsWith('url("#') && url.endsWith('")')){
		const id=url.substring(6,url.length-2);
		const _ele=ele.ownerSVGElement.getElementById(id);
		if(_ele instanceof type)
			return <InstanceType<T>>_ele;
	}
	return null;
}

function getFill(ele:SVGGraphicsElement, opacity:number, styles:CSSStyleDeclaration):Fill{
	if(ele instanceof SVGLineElement)
		return null;
	if(ele instanceof SVGPolylineElement && ele.points.length<=2)
		return null;
	let pattern=getEleFromUrl(ele,styles.fill,SVGPatternElement);
	if(pattern instanceof SVGPatternElement)
		return pattern;

	let fill=fromColor(styles.fill);
	fill.opacity*=opacity;
	if(fill.opacity<=0)
		return null;
	return fill;
}

function getStroke(opacity:number, styles:CSSStyleDeclaration){
	let stroke=new Stroke();
	stroke.color=fromColor(styles.stroke);
	stroke.color.opacity*=opacity;
	stroke.width=fromPx(styles.strokeWidth);
	stroke.dash=styles.strokeDasharray!=='none'?styles.strokeDasharray.split(' ').map(v=>fromPx(v)):null;
	stroke.nonScalingStroke=styles[<keyof CSSStyleDeclaration>'vectorEffect']==='non-scaling-stroke';
	stroke.lineCap=styles.strokeLinecap;
	if(stroke.drawable())
		return stroke;
	return null;
}

function getClip(ele:SVGGraphicsElement, styles:CSSStyleDeclaration){
	if(styles.clipPath.startsWith('url("#') && styles.clipPath.endsWith('")')){
		const clipPathId=styles.clipPath.substring(6,styles.clipPath.length-2);
		const clipPathEle=ele.ownerSVGElement.getElementById(clipPathId);
		if(!(clipPathEle instanceof SVGClipPathElement))
			return null;
		const pathEle=clipPathEle.firstChild;
		if(!(pathEle instanceof SVGPathElement))
			return null;
		const d=pathEle.getAttribute('d');
		if(d)
			return d;
	}
	return null;
}

export class PdfSvgRenderer{
	public constructor(
		private width:number,
		private height:number,
		private readonly svg:SVGSVGElement,
		private readonly rdr:PdfRenderer,
	){
	}

	private opacity:number[]=[1];
	// private transformStack:DOMMatrix[];

	private applySVGTransform(
		ele:SVGGraphicsElement,
		run:()=>void,
	){
		const m=new DOMMatrix();
		for(const item of Array.from(ele.transform.baseVal)){
			m.multiplySelf(item.matrix);
		}
		this.rdr.transform(m,run);
	}

	private pathRect(ele:SVGRectElement):PdfRenderer.Path{
		const r=attrToPoint(ele.rx,ele.ry);
		if(r.y===0)
			r.y=r.x;

		const size=attrToPoint(ele.width,ele.height);
		const pos=attrToPoint(ele.x,ele.y);
		return {
			rect: new math.Box2(pos,pos.clone().add(size)),
			cornerRadii: [r,r,r,r],
		};
	}

	private pathCircle(ele:SVGCircleElement){
		const center=attrToPoint(ele.cx,ele.cy)
		const r=attrToNum(ele.r);
		const c=r*KAPPA;
		const points=[
			center.clone().translate(-r, 0),
			center.clone().translate(-r,-c),
			center.clone().translate(-c,-r),

			center.clone().translate( 0,-r),
			center.clone().translate(+c,-r),
			center.clone().translate(+r,-c),

			center.clone().translate(+r, 0),
			center.clone().translate(+r,+c),
			center.clone().translate(+c,+r),

			center.clone().translate( 0,+r),
			center.clone().translate(-c,+r),
			center.clone().translate(-r,+c),
		];

		return (pather:PdfRenderer.Pather)=>{
			pather.moveTo(points[0]);
			pather.bezierCurveTo(points[1], points[2], points[3]);
			pather.bezierCurveTo(points[4],points[5],points[6]);
			pather.bezierCurveTo(points[7],points[8],points[9]);
			pather.bezierCurveTo(points[10],points[11],points[0]);
			pather.closePath();
		}
	}

	private pathEllipse(ele:SVGEllipseElement){
		const center=attrToPoint(ele.cx,ele.cy)
		const r=attrToPoint(ele.rx,ele.ry);
		const c=r.clone().multiplyScalar(KAPPA);
		const points=[
			center.clone().translate(-r.x,   0),
			center.clone().translate(-r.x,-c.y),
			center.clone().translate(-c.x,-r.y),

			center.clone().translate(0,   -r.y),
			center.clone().translate(+c.x,-r.y),
			center.clone().translate(+r.x,-c.y),

			center.clone().translate(+r.x,0),
			center.clone().translate(+r.x,+c.y),
			center.clone().translate(+c.x,+r.y),

			center.clone().translate(0,   +r.y),
			center.clone().translate(-c.x,+r.y),
			center.clone().translate(-r.x,+c.y),
		];

		return (pather:PdfRenderer.Pather)=>{
			pather.moveTo(points[0]);
			pather.bezierCurveTo(points[1], points[2], points[3]);
			pather.bezierCurveTo(points[4],points[5],points[6]);
			pather.bezierCurveTo(points[7],points[8],points[9]);
			pather.bezierCurveTo(points[10],points[11],points[0]);
			pather.closePath();
		};
	}

	private pathLine(ele:SVGLineElement){
		return [attrToPoint(ele.x1,ele.y1),attrToPoint(ele.x2,ele.y2)];
	}

	private pathPolyline(ele:SVGPolylineElement){
		return Array.from(ele.points);
	}

	private pathPath(ele:SVGPathElement){
		const d=ele.getAttribute('d');
		if(d){
			const path=SvgPath.parse(d);
			return (pather:PdfRenderer.Pather)=>path.apply(pather);
			// path.apply({
			// 	closePath: ()=>this.closePath(),
			// 	lineTo: p=>this.lineTo(p),
			// 	moveTo: p=>this.moveTo(p),
			// 	bezierCurveTo: (cp1,cp2,p)=>this.bezierCurveTo(cp1,cp2,p),
			// 	quadraticCurveTo: (cp,p)=>this.quadraticCurveTo(cp,p),
			// });
			// this.rdr.path(d);
		}
		return null;
	}

	public pathOfElement(ele:SVGElement):PdfRenderer.Path{
		if(ele instanceof SVGRectElement)
			return this.pathRect(ele);
		if(ele instanceof SVGCircleElement)
			return this.pathCircle(ele);
		if(ele instanceof SVGEllipseElement)
			return this.pathEllipse(ele);
		if(ele instanceof SVGLineElement)
			return this.pathLine(ele);
		if(ele instanceof SVGPolylineElement)
			return this.pathPolyline(ele);
		if(ele instanceof SVGPathElement)
			return this.pathPath(ele);
		return null;
	}

	private getDrawProps(
		ele:SVGGraphicsElement,
		styles:CSSStyleDeclaration,
	):RenderProps{
		const opacity=this.opacity.at(-1);
		const fill=getFill(ele,opacity,styles);
		const stroke=getStroke(opacity,styles);

		if(!(fill || stroke))
			return null;

		const clipPath=getClip(ele,styles);

		const paintOrder:RenderProps['paintOrder']=[];
		if(fill)
			paintOrder.push('fill');
		if(stroke)
			paintOrder.push('stroke');
		if(styles.paintOrder==='stroke')
			paintOrder.reverse();

		let markerStart=getEleFromUrl(ele,styles.markerStart,SVGMarkerElement);
		let markerEnd=getEleFromUrl(ele,styles.markerEnd,SVGMarkerElement);

		return {
			fill,
			stroke,
			paintOrder,
			clipPath,
			markerStart,
			markerEnd,
		};
	}

	private *iteratePattern(patternEle:SVGPatternElement){
		yield *[];
		const pw=patternEle.width.baseVal.value;
		const ph=patternEle.height.baseVal.value;
		for(let y=0;y<this.height;y+=ph){
			for(let x=0;x<this.width;x+=pw){
				yield [x,y];
			}
		}
	}

	private fillPattern(pattern:SVGPatternElement){
		for(const [x,y] of this.iteratePattern(pattern)){
			this.rdr.translate(x,y,()=>{
				for(const child of Array.from(pattern.children)){
					if(child instanceof SVGElement)
						this.drawElement(child);
				}
			});
		}
	}

	private drawMarker(
		path:SVGPathElement,
		marker:SVGMarkerElement,
		stroke:Stroke,
		distAlong:number,
	){
		if(!marker)
			return;
		const pathLength=path.getTotalLength();
		if(distAlong<0)
			distAlong+=pathLength;
		const a=math.Vec2.from(path.getPointAtLength(math.clamp(distAlong-pathLength*0.05,0,pathLength)));
		const b=math.Vec2.from(path.getPointAtLength(math.clamp(distAlong+pathLength*0.05,0,pathLength)));
		const p=math.Vec2.from(path.getPointAtLength(math.clamp(distAlong,0,pathLength)));
		const v=b.clone().sub(a).normalize();
		if(!v.isFinite())
			return;

		const markerWidth=attrToNum(marker.markerWidth)*stroke.width;
		const markerHeight=attrToNum(marker.markerHeight)*stroke.width;
		const markerUnits=marker.markerUnits.baseVal;//should be 2 for stroke width
		const refX=attrToNum(marker.refX);
		const refY=attrToNum(marker.refY);

		const m=new DOMMatrix().translateSelf(p.x,p.y).rotateSelf(math.R2D*v.angle());
		m.multiplySelf(getViewBoxTransform(marker,math.Box2.from(0,0,markerWidth,markerHeight)));
		m.translateSelf(-refX,-refY);
		this.rdr.transform(m,()=>{
			this.drawElements(marker);
		});
	}

	private drawFillAndStroke(ele:SVGGraphicsElement){
		const styles=getComputedStyle(ele);
		const props=this.getDrawProps(ele,styles);
		if(!props)
			return;

		const {rdr}=this;

		this.rdr.clip(
			props.clipPath,
			()=>{
				let {fill,stroke,paintOrder}=props;
				if(paintOrder[0]==='fill' && paintOrder[1]==='stroke' && !(fill instanceof SVGPatternElement) && !stroke.nonScalingStroke){
					rdr.fillAndStroke(this.pathOfElement(ele),fill,stroke);
					paintOrder.length=0;
				}

				for(const step of paintOrder){
					if(step==='fill'){
						
						if(fill instanceof SVGPatternElement){
							const pattern=fill;
							rdr.clip(this.pathOfElement(ele),()=>{
								this.fillPattern(pattern);
							});
						}else{
							rdr.fill(this.pathOfElement(ele),fill);
						}
					}else if(step==='stroke'){
						if(stroke.nonScalingStroke){
							this.rdr.runTransformManually(()=>{
								this.rdr.stroke(this.pathOfElement(ele),stroke);
							});
						}else{
							rdr.stroke(this.pathOfElement(ele),stroke);
						}
					}
				}

				if(ele instanceof SVGPathElement && stroke){
					this.drawMarker(ele,props.markerStart,stroke,0);
					this.drawMarker(ele,props.markerStart,stroke,ele.getTotalLength());
				}
			}
		);

	}

	public drawElements(ele:SVGSVGElement|SVGMarkerElement){
		for(let i=0;i<ele.children.length;++i){
			const child=ele.children.item(i);
			if(child instanceof SVGElement)
				this.drawElement(child);
		}
	}

	public getForeignElements() {
		const out: HTMLElement[] = [];
		for (const foreignObject of Array.from(this.svg.querySelectorAll('foreignObject'))) { // Convert NodeList to an array
			for (const child of Array.from(foreignObject.children)) { // Convert HTMLCollection to an array
				if (child instanceof HTMLElement) {
					out.push(child);
				}
			}
		}
		return out;
	}
	

	private drawElement(ele:SVGElement){
		if(ele instanceof SVGGElement){
			this.drawGroupElement(ele);
		}else if(ele instanceof SVGTextElement){
			this.drawTextElement(ele);
		}else if(ele instanceof SVGForeignObjectElement){
			//we don't handle this here, handled in html element code
		}else if(ele instanceof SVGRectElement){
			this.drawFillAndStroke(ele);
		}else if(ele instanceof SVGCircleElement){
			this.drawFillAndStroke(ele);
		}else if(ele instanceof SVGEllipseElement){
			this.drawFillAndStroke(ele);
		}else if(ele instanceof SVGLineElement){
			this.drawFillAndStroke(ele);
		}else if(ele instanceof SVGPolylineElement){
			if(ele.points.length<2)
				return;
			this.drawFillAndStroke(ele);
		}else if(ele instanceof SVGPathElement){
			this.drawFillAndStroke(ele);
		}
	}

	private drawGroupElement(ele:SVGGElement){
		const styles=getComputedStyle(ele);
		this.opacity.push(+styles.opacity);
		this.applySVGTransform(ele,()=>{
			for(const child of Array.from(ele.children)){
				if(child instanceof SVGElement)
					this.drawElement(child);
			}
		});
		this.opacity.pop();
	}

	private drawTextElement(ele:SVGTextElement){
		const {rdr}=this;
		const styles=getComputedStyle(ele);
		const renderProps=this.getDrawProps(ele,styles);
		const text=ele.textContent;
		if(text!=='' && renderProps){
			if(ele.x.baseVal.length===0 || ele.y.baseVal.length===0)
				return;
			let x=ele.x.baseVal.getItem(0).value;
			let y=ele.y.baseVal.getItem(0).value;

			this.applySVGTransform(ele,()=>{
				let {fill,stroke,paintOrder}=renderProps;
				if(fill instanceof SVGPatternElement)
					fill=<ParsedColor>null;

				const font=getFont(styles);
				//its negative in svgs
				const baseline=-getBaseline(font.size,font.size*1.125,font,alignmentBaselineToBaseline[styles.alignmentBaseline]);
				let width=rdr.widthOfString(text);
				if(styles.textAnchor==='middle')
					x-=width*0.5;
				else if(styles.textAnchor==='end')
					x-=width;

				rdr.applyTextStyles({
					font,
					fill,
					stroke,
				});
				if(paintOrder[0]==='fill' && paintOrder[1]==='stroke'){
					rdr.drawText(text,x,y,baseline,true,true);
				}else{
					for(const step of paintOrder){
						if(step==='fill'){
							rdr.drawText(text,x,y,baseline,true,false);
						}else if(step==='stroke'){
							rdr.drawText(text,x,y,baseline,false,true);
						}
					}
				}
			});
		}
	}
}


function getViewBoxTransform(
	svg:SVGSVGElement|SVGMarkerElement,
	svgRect:math.Box2,
){
	const viewBox=svg.viewBox.baseVal;
	const preAspRat=svg.preserveAspectRatio.baseVal;
	const m=new DOMMatrix();
	m.translateSelf(svgRect.min.x,svgRect.min.y);
	const svgSize=svgRect.size();
	if(viewBox && viewBox.width>0 && viewBox.height>0 && (preAspRat.meetOrSlice===preAspRat.SVG_MEETORSLICE_MEET || preAspRat.meetOrSlice===preAspRat.SVG_MEETORSLICE_SLICE)){
		const translate=new math.Vec2(0,0);
		const scale=new math.Vec2(svgSize.x/viewBox.width,svgSize.y/viewBox.height);
		if(preAspRat.align!==preAspRat.SVG_PRESERVEASPECTRATIO_NONE){
			if(preAspRat.meetOrSlice===preAspRat.SVG_MEETORSLICE_MEET){
				scale.x=math.min(scale.x,scale.y);
			}else{// if(preAspRat.meetOrSlice===preAspRat.SVG_MEETORSLICE_SLICE){
				scale.x=math.max(scale.x,scale.y);
			}
			scale.y=scale.x;
			const w=viewBox.width*scale.x;
			const h=viewBox.height*scale.x;
			if(preAspRat.align===preAspRat.SVG_PRESERVEASPECTRATIO_XMIDYMIN || preAspRat.align===preAspRat.SVG_PRESERVEASPECTRATIO_XMIDYMID || preAspRat.align===preAspRat.SVG_PRESERVEASPECTRATIO_XMIDYMAX)
				translate.x=(svgSize.x-w)*0.5;
			else if(preAspRat.align===preAspRat.SVG_PRESERVEASPECTRATIO_XMAXYMIN || preAspRat.align===preAspRat.SVG_PRESERVEASPECTRATIO_XMAXYMID || preAspRat.align===preAspRat.SVG_PRESERVEASPECTRATIO_XMAXYMAX)
				translate.x=svgSize.x-w;
			if(preAspRat.align===preAspRat.SVG_PRESERVEASPECTRATIO_XMINYMID || preAspRat.align===preAspRat.SVG_PRESERVEASPECTRATIO_XMIDYMID || preAspRat.align===preAspRat.SVG_PRESERVEASPECTRATIO_XMAXYMID)
				translate.y=(svgSize.y-h)*0.5;
			else if(preAspRat.align===preAspRat.SVG_PRESERVEASPECTRATIO_XMINYMAX || preAspRat.align===preAspRat.SVG_PRESERVEASPECTRATIO_XMIDYMAX || preAspRat.align===preAspRat.SVG_PRESERVEASPECTRATIO_XMAXYMAX)
				translate.y=svgSize.y-h;
		}
		m.translateSelf(translate.x,translate.y);
		m.scaleSelf(scale.x,scale.y);
		m.translateSelf(-viewBox.x,-viewBox.y);
	}
	return m;
}

export namespace PdfSvgRenderer{
	export function draw(rdr:PdfRenderer, svg:SVGSVGElement, clientRect:math.Box2){
		//const m=new DOMMatrix().translate(clientRect.x,clientRect.y);
		const intialTransform=getViewBoxTransform(svg,clientRect);

		const svgRdr=new PdfSvgRenderer(clientRect.size('x'),clientRect.size('y'),svg,rdr);
		rdr.transform(intialTransform,()=>{
			svgRdr.drawElements(svg);
		},true);
		return svgRdr.getForeignElements();
	}
}
