import { Component, OnInit } from '@angular/core';
import { ChartDataService } from '@app/backend';

@Component({
  selector: 'app-drill-and-blast-dashboard',
  templateUrl: './drill-and-blast-dashboard.component.html',
  styleUrls: ['./drill-and-blast-dashboard.component.scss']
})

export class DrillAndBlastDashboardComponent implements OnInit {
	private selectedTab: string = 'Hole Count & Length';

	constructor(private readonly chartDataService: ChartDataService) {}

	ngOnInit(): void {
		this.chartDataService.selectedTab$.next(this.selectedTab);
	}

	public onSequenceChangeEvent(ev) {
		this.chartDataService.selectedTab$.next(ev.tab?.textLabel);
	}
}
