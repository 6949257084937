import { getFont } from "./fonts";
import { PageMeta } from "./page-meta";
import { PdfRenderer } from "./pdf-renderer";
import { ParsedColor,fromColor } from "./shared";

export class TextChild{
	public x=0;
	public trimmedWidth=this.width;

	public constructor(
		public readonly ele:Text|HTMLElement,
		public text:string,
		public font:{family:string,size:number},
		public verticalAlign:string,
		public fill:ParsedColor,
		public width?:number,
		public height?:number,
	){
	}

	public mergeable(that:TextChild){
		return (
			this.text
			&& that.text
			&& that.font.family===this.font.family
			&& that.font.size===this.font.size
			&& that.verticalAlign===this.verticalAlign
			&& that.fill===this.fill
		);
	}

	public calcWidth(rdr:PdfRenderer){
		if(this.text){
			rdr.applyTextStyles(this);
			this.width=rdr.widthOfString(this.text);
			this.height=rdr.heightOfString(this.text);
			this.trimmedWidth=rdr.widthOfString(this.text.trim());
		}
	}

	public hasText(){
		return typeof(this.text)==='string';
	}

	public applyStyles(rdr:PdfRenderer){
		rdr.applyTextStyles(this);
	}
}

export class TextLine{
	public constructor(
	){
	}

	public children:TextChild[]=[];
	public width=0;

	public append(
		rdr:PdfRenderer,
		child:TextChild,
		x:number,
	){
		let last=this.children.at(-1);
		if(last?.mergeable(child)){
			last.text+=child.text;
			last.calcWidth(rdr);
		}else{
			child.x=x;
			this.children.push(child);
		}
		last=this.children.at(-1);
		this.width=last.x+last.trimmedWidth;
	}

	public isEmpty(){
		return this.children.length===0;
	}

	public [Symbol.iterator](){
		return this.children.values();
	}
}

function getFontColor(styles:CSSStyleDeclaration){
	const color=fromColor(styles.color);
	color.opacity*=+styles.opacity;
	return color;
}

// function isPartOfTextFlow(meta:ElementMeta, ele:HTMLElement){
// 	const {styles}=meta;
// 	return (styles.display==='inline' && (styles.position==='static' || styles.position==='relative') && ele.childElementCount===0)
// }

export function elementToTextChild(page:PageMeta, container:HTMLElement, styles:CSSStyleDeclaration):TextChild[]{
	// if(styles.writingMode==='vertical-rl')
	// 	containerRect={width:containerRect.height,height:containerRect.width};

	const nodes=page.meta.getFlatNodes(container);
	if(nodes.length===0)
		return [];

	let children:TextChild[]=[];

	const font=getFont(styles);
	const verticalAlign=styles.verticalAlign;
	const color=getFontColor(styles);

	for(const ele of nodes){
		if(ele instanceof Text){
			let text=ele.textContent.replace(/^\s+/,'');
			if(text.length===0)
				continue;
			if(styles.textTransform==='uppercase')
				text=text.toUpperCase();

			const words=text.match(/[^\s]+\s*/g);
			if(words){
				for(const word of words){
					children.push(new TextChild(
						ele,
						word,
						font,
						verticalAlign,
						color,
					));
				}
			}
			continue;
		}

		if(!(ele instanceof HTMLElement))
			continue;

		const meta=page.meta.get(ele);
		if(!meta)
			continue;

		if(meta.styles.display==='inline-block' || ele instanceof HTMLImageElement){
			children.push(new TextChild(
				ele,
				null,
				font,
				// lineHeight,
				verticalAlign,
				color,
				meta.rect.size('x'),
				meta.rect.size('y'),
			));
			continue;
		}

		if(meta.isPartOfTextFlow()){
			children=children.concat(elementToTextChild(page,ele,meta.styles));
		}
	}
	return children;
}
