import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { SiteService } from '@app/app-state/site.service';
import { UserService } from '@app/app-state/user.service';
import * as backend from '@app/backend';
import { ChartDataService } from '@app/backend/chart-data.service';
import { GenerateChartDataService } from '@app/backend/generate-chart-data.service';
import { ChartDataInterface, FineGoalDataInterface, FineGoalParams, FragmentationInterface } from "@app/models/chart-data.model";
import * as charts from '@utilities/highchart-objectify';
import * as Highcharts from 'highcharts/highstock';
import { Subscription } from 'rxjs';


@Component({
	selector: 'app-fragmentation-table-view',
	templateUrl: './fragmentation-table-view.component.html',
	styleUrls: ['./fragmentation-table-view.component.scss']
})
export class FragmentationTableViewComponent implements OnInit, OnDestroy {

	public Highcharts: typeof Highcharts = Highcharts; // required
	public chartOptions = [];
	public loading:boolean;
	public isInputChanged:boolean = false;
	public selectedMatTabIndex: number = 0;
	public siteId: number;

	private subscriptions: Subscription = new Subscription();
	private subdomainId: number;
	private domainId: number;
	private startDate: string;
	private endDate: string;
	private selectedTimeline: string;
	public tableLoading: boolean = false;
	private datasetMap: { [name: string]: number } = {};

	public displayedColumns: string[] = ['project', 'fines', 'finesGoal' , 'oversize', 'oversizeGoal'];
	public dataSource: MatTableDataSource<any>;
	@ViewChild(MatPaginator) paginator: MatPaginator;
	
	private fragmentaitonData:ChartDataInterface[] = [];
	private finesGoalData: FineGoalDataInterface[] = null;
	constructor(
		private readonly chartDataService: ChartDataService, 
		private snackBar: MatSnackBar,
		private readonly siteService: SiteService,
		private generateChartDataService:GenerateChartDataService,
		private userService: UserService
	) {}

	ngOnInit() {
		this.subscriptions.add(this.chartDataService.loading$.subscribe(loading => {
			this.loading = loading;
		}));	

		this.subscriptions.add(this.chartDataService.fragmentationData$.subscribe(details => {
			this.triggerLoadData(details);
		}));

		this.subscriptions.add(this.siteService.domainId$.subscribe(domainId => {
			this.domainId = domainId;
			if (this.domainId) {		
				this.getData();
			}
		}));

		this.subscriptions.add(this.siteService.subDomainId$.subscribe(subdomainId => {
			this.subdomainId = subdomainId;
			this.triggerLoadData();
		}));
		
		this.subscriptions.add(this.siteService.siteId$.subscribe(siteId => {
			this.siteId = siteId;
			this.finesGoalData = null;
			this.triggerLoadData(this.fragmentaitonData);
			if (this.siteId) {
				this.fetchFragmentationSizeRangeTableData();
			}
		}));

		this.subscriptions.add(this.siteService.startDate$.subscribe(startDate => {
			this.startDate = startDate;
			this.triggerLoadData();
		}));

		this.subscriptions.add(this.siteService.endDate$.subscribe(endDate => {
			this.endDate = endDate;
			this.triggerLoadData();
		}));

		this.subscriptions.add(this.siteService.selectedTimeline$.subscribe(selectedTimeline => {
			this.selectedTimeline = selectedTimeline;
			this.triggerLoadData();
		})); 	

		this.subscriptions.add(this.userService.userUnitMeasurement$.subscribe(() => {
			this.triggerLoadData();
		})); 

		this.subscriptions.add(this.chartDataService.fragmentationData$.subscribe(details => {
			this.fetchFragmentationSizeRangeTableData();
		}));
	}


	private getData(){
		this.subscriptions.add(this.chartDataService.getCacheFragmentationData().subscribe(details => {
			this.triggerLoadData(details);
		}))
	}

	private loadData (chartData: ChartDataInterface[]) {
		const data: FragmentationInterface= this.generateChartDataService.generateChartData(chartData);

		for (const key in data.fragmentationGraphData) {
			this.chartOptions = charts.generateChartOptions(data.fragmentationGraphData[key]);
		}
	}

	private async fetchFragmentationSizeRangeTableData() {
		const response = this.chartOptions[1];
		if (response) {
			let seriesData = response.series.filter(series => series?.type != "line");
			const finesSeries = seriesData[seriesData?.length - 1];
			const oversizeSeries = seriesData[0];
			const categories = response.xAxis.categories;
			const datasetIds = response.datasetIds;

			categories.forEach((category, index) => {
				this.datasetMap[category] = datasetIds[index];
			});
  
		  if (finesSeries) {
				if (!this.finesGoalData) {
					const fineParmas = {siteId: this.siteId};
					this.tableLoading = true;
					const response = await backend.updateFragSizeRangeFineGoals(fineParmas);
					this.tableLoading = false;
					this.finesGoalData = response.data;
				}
				if(this.finesGoalData.length === 0) {
					const formattedData = finesSeries.data.map((value, index) => ({
						project: categories[index],
						fines: value,
						finesGoal: 0,
						datasetId: this.datasetMap[categories[index]],
						oversize: oversizeSeries.data[index],
						oversizeGoal: 0
					}));
		
					this.dataSource = new MatTableDataSource(formattedData);
					this.dataSource.paginator = this.paginator;
				}
				else {
					this.dataSource = new MatTableDataSource(this.finesGoalData);
					this.dataSource.paginator = this.paginator;
				}
			}
		}
	}
	
	public onInputChange() {
		this.isInputChanged = true;
	}

	public addFinesGoalValues() {
		if(this.isInputChanged) {
			const updatedData = this.dataSource.data;
			const fineGoalParams: FineGoalParams = {
				siteId: this.siteId,
				goalArray: updatedData.map(item => ({
					project: item.project,
					fines: item.fines,
					finesGoal: item.finesGoal,
					datasetId:item.datasetId,
					oversizeGoal: item?.oversizeGoal,
					oversize: item?.oversize
				}))
			};

			this.updateFragSizeRangeFineGoals(fineGoalParams);
			this.isInputChanged = false;
		}
		else {
			this.snackBar.open('Goals not added/updated', ' ', { horizontalPosition: 'right', verticalPosition: 'top', duration: 3 * 1000 });
		}
	}

	private async updateFragSizeRangeFineGoals(fineGoalParams: FineGoalParams) {
		try {
			this.tableLoading = true;
			const response = await backend.updateFragSizeRangeFineGoals(fineGoalParams);
			this.tableLoading = false;
			if (response) {
				this.finesGoalData = response.data;
			}

			this.snackBar.open(response.message, ' ', { horizontalPosition: 'right', verticalPosition: 'top', duration: 3 * 1000 });
			const originalFragmentationData = this.generateChartDataService.originalFragmentationData;
			response.data.map((res)=>{
				originalFragmentationData.map((fragmentationData,i)=>{
					if (res.datasetId == fragmentationData.datasetId) {
						originalFragmentationData[i]['oversizeGoal'] = res.oversizeGoal;
						originalFragmentationData[i]['finesGoal'] = res.finesGoal;
					}
				});
			})
			this.chartDataService.fragmentationData$.next(originalFragmentationData);
		} catch (error) {
			console.error("API Error", error);
		}
	}

	private triggerLoadData(data?: ChartDataInterface[]) {
		const originalFragmentationData = data || this.generateChartDataService.originalFragmentationData
		this.fragmentaitonData = this.generateChartDataService.getFilteredData(
			originalFragmentationData,
			this.domainId,
			this.subdomainId,
			this.siteId,
			this.startDate,
			this.endDate,
			this.selectedTimeline
		);
		this.loadData(this.fragmentaitonData);
	}

	ngOnDestroy() {
		this.subscriptions.unsubscribe();
	}
}
