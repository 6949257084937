import { Injectable } from '@angular/core';

import { BehaviorSubject, combineLatest, distinctUntilChanged, of, switchMap, take } from 'rxjs';
import { getPromise } from "./request-promise";

import { SiteService } from '../app-state/site.service';

import { ChartDataInterface, FielderDataset } from "@app/models/chart-data.model";
import { GenerateChartDataService } from './generate-chart-data.service';
@Injectable({
	providedIn: 'root'
})

export class ChartDataService {

	readonly drillDetails$ = new BehaviorSubject<ChartDataInterface[]>(null);
	readonly fragmentationData$ = new BehaviorSubject<ChartDataInterface[]>(null);
	readonly fielderAnalytics$ = new BehaviorSubject<ChartDataInterface[]>(null);
	readonly fielderAnalyticsByDrill$ = new BehaviorSubject<ChartDataInterface[]>(null);
	readonly allFielderDataset$ = new BehaviorSubject<FielderDataset[]>(null);
	readonly exportedGraphData$ = new BehaviorSubject(null);
	readonly loading$ = new BehaviorSubject<boolean>(false);
	readonly selectedTab$ = new BehaviorSubject<string>('');

	public get allFielderDataset() {
		return this.allFielderDataset$.value;
	};

	private domainId;
	private subdomainId;
	private siteId;
	private selectedDrillMachine;

	constructor(private siteService: SiteService, private generateChartDataService:GenerateChartDataService){
		combineLatest([
			siteService.domainId$,
		]).pipe(
			distinctUntilChanged()
		)
		.subscribe(([domainId]) => {
			this.domainId = domainId;
			this.siteId = this.siteId
			if(this.domainId) {
				this.resetAllValues();
		}
		});

		this.siteService.selectedDrillMachine$.subscribe(selectedDrillMachine => {
			if(this.selectedDrillMachine !== selectedDrillMachine && selectedDrillMachine !== undefined) {
				this.selectedDrillMachine = selectedDrillMachine
				this.resetAllValues();
			}
		});
	}

	private resetAllValues() {
		this.drillDetails$.next(null);
		this.fragmentationData$.next(null);
		this.fielderAnalytics$.next(null);
		this.fielderAnalyticsByDrill$.next(null);
	}


	public getAllDrillDetails() {
		this.loading$.next(true);
		return getPromise(
			`api/dashboards/highchart_analytics/drill_details?&domain_id=${this.domainId}`,
			(data) => data.data
		).then((details) => {
			this.generateChartDataService.originalDrillAndBalstData = details;
			this.drillDetails$.next(details);
			this.loading$.next(false);
			return details
		});
	}

	public getAllFragmentationData() {
		return getPromise(
			`/api/dashboards/highchart_analytics/fragmentation_chart_data?&domain_id=${this.domainId}`,
		(data) => data.data).then((details) => {
			this.generateChartDataService.originalFragmentationData = details;
			this.fragmentationData$.next(details)
		    this.loading$.next(false);
			return details
		});
	}

	public getAllFielderAnalytics() {
		this.loading$.next(true);
		return getPromise(
			`/api/dashboards/highchart_analytics/fielder_analytics_data?${this.apiParams()}`,
		(data) => data.data).then((details) => {
			this.generateChartDataService.originalFielderAnalytics = details;
			this.fielderAnalytics$.next(details)
			this.loading$.next(false);
			return details
		});
	}

	public getAllFielderAnalyticsByDrill() {
		this.loading$.next(true);
		return getPromise(
			`/api/dashboards/highchart_analytics/fielder_analytics_drill_data?${this.apiParams()}`,
		(data) => data.data).then((details) => {
			if(!this.selectedDrillMachine) {
				this.generateChartDataService.originalFielderAnalyticsByDrill = details;
			} 
			this.fielderAnalyticsByDrill$.next(details)
			this.selectedDrillMachine = null;
			this.loading$.next(false);
			return details;
		});
	}

	public getDrillMachines(subdomainId) {
		this.loading$.next(true);
		return getPromise(
			`/api/dashboards/highchart_analytics/fetch_drill_machines?subdomain_id=${subdomainId}`,
		(data) => data.data).then((details) => {
			this.allFielderDataset$.next(details)
			this.loading$.next(false);
			return details;
		});
	}

	public getCacheDrillDetails() {
		return this.drillDetails$.pipe(
			take(1),
			switchMap((details) => {
			this.loading$.next(true);
			if (details === null) {
				return this.getAllDrillDetails();
			}
			this.loading$.next(false);
			return of(details);
			}),
		);
	}

	public getCacheFragmentationData() {
		return this.fragmentationData$.pipe(
			take(1),
			switchMap((details) => {
			this.loading$.next(true);
			if (details === null) {
				return this.getAllFragmentationData();
			}
			this.loading$.next(false);
			return of(details);
			}),
		);
	}

	public getCacheFielderAnalytics() {
		return this.fielderAnalytics$.pipe(
			take(1),
			switchMap((details) => {
			this.loading$.next(true);
			if (details === null) {
				return this.getAllFielderAnalytics();
			}
			this.loading$.next(false);
			return of(details);
			}),
		);
	}

	public getCacheFielderAnalyticsByDrill() {
		return this.fielderAnalyticsByDrill$.pipe(
			take(1),
			switchMap((details) => {
			this.loading$.next(true);
			if (details === null) {
				return this.getAllFielderAnalyticsByDrill();
			}
			this.loading$.next(false);
			return of(details);
			}),
		);
	}

	public apiParams(){
		if(this.selectedDrillMachine) {
			return `${this.domainId ? '&domain_id=' + this.domainId : ''}${this.subdomainId ? '&subdomain_id=' + this.subdomainId : ''}${this.selectedDrillMachine ? '&uuid=' + this.selectedDrillMachine : ''}`
		}
		else {
			return `${this.domainId ? '&domain_id=' + this.domainId : ''}`;
		}
	}
}


  