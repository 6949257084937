import { async } from '@lib/async';
import * as pdfkit from './pdf';
import { BehaviorSubject } from '@rxjs';
import * as blobStream from './blob-stream';
import { loadFonts } from './fonts';
import { PageMeta } from './page-meta';
import { pageSizes } from './page-sizes';
import { PdfRenderer } from './pdf-renderer';
import { PdfHTMLRenderer } from './pdf-html-renderer';
export { pageSizes } from './page-sizes';

//page size in printer points, 1/72 inch, 0.352777778mm

function sizeCss(css:Set<string>, selector:string, size:string, orientation:string|null, w:string, h:string){
	if(selector.includes('${size}')){
		const body=
`width: ${w};
min-width: ${w};
max-width: ${w};
height: ${h};
min-height: ${h};
max-height: ${h};
--page-width: ${w};
--page-height: ${h};`;
		
		selector=selector.replace(/\$\{size\}/g,size);
		selector=selector.replace(/\$\{orientation\}/g,orientation);
		selector=selector.replace(/\$\{body\}/g,body);
		css.add(selector);
	}
}

export async function initialize(id:string, selectors:string[]){
	const libPromise=Promise.all([blobStream.load(),pdfkit.load()]);

	if(!document.getElementById(id)){
		const css=new Set<string>();
		for(const key in pageSizes){
			const sizes=(<number[]>pageSizes[key]).map(v=>{
				const inches=v/72;
				const mm=v*0.352777778;
				const best=[[inches,'in'],[v,'pt'],[mm,'mm']].lowest(v=>Math.abs((<number>v[0])%1));
				return best.join('');
			});
			for(const selector of selectors){
				sizeCss(css,selector,key,'portrait',sizes[0],sizes[1]);
				sizeCss(css,selector,key,'landscape',sizes[1],sizes[0]);
			}
		}
		const style=document.createElement('style');
		style.id=id;
		style.setAttribute('id',id);
		style.innerHTML=[...css].join("\n");
		document.head.appendChild(style);
	}
	return libPromise;
}



class Converter{
	private doc:pdfkit.PDFDocument=null;
	private pages:PageMeta[]=[];
	//private pageNumber=1;

	public async convert(
		pageElements:HTMLElement[],
		progress$:BehaviorSubject<number>
	){
		this.buildPageInfo(pageElements);

  
		await document.fonts.ready;

		let stream:blobStream.BlobStream=null;
		let top=0;
		if(this.pages.length>0){
			top=this.pages[0].rect.min.x;
		}
		let pageNumber=1;
		for(const page of this.pages){
			if(!this.doc){
				this.doc=pdfkit?.newPDFDocument({
					size: page.size,
					margin: 0,
					layout: page.layout,
				});
				await loadFonts(this.doc);
				stream=this.doc.pipe(blobStream.blobStream());
			}else{
				this.doc.addPage({
					size: page.size,
					layout: page.layout,
				});
			}
			this.convertPage(page);
			progress$.next((pageNumber++)/this.pages.length);
			await async.animationFrame();
		}
		progress$.next(1);

		this.doc?.end();
		return stream;
	}

	public buildPageInfo(elements:HTMLElement[]){
		this.pages.length=0;
		for(const element of elements){
			const page=new PageMeta(element);
			page.init();
			this.pages.push(page);
		}
		return this.pages;
	}

	private convertPage(page:PageMeta){
		const doc=this.doc;
		doc.save();
		doc.scale(page.ptsPerPx);

		for(const child of Array.from(page.element.children)){
			if((child instanceof HTMLElement || child instanceof SVGSVGElement)){
				const rdr=new PdfHTMLRenderer(new PdfRenderer(doc),page);
				rdr.drawElement(page.rect,child,0);
			}
		}
		doc.restore();
	}
};

export async function convert(pages:HTMLElement|HTMLElement[], progress$:BehaviorSubject<number>){
	if(!Array.isArray(pages))
		pages=[pages];

	pages=pages.filter(page=>{
		const style=getComputedStyle(page);
		return style.visibility==='visible' && style.display!=='none';
	});

	if(pages.length===0)
		return null;

	const converter=new Converter();
	
	const stream=await converter.convert(pages,progress$);

	const blob=await new Promise<Blob>(resolve=>{
		stream.on('finish',()=>{
			const blob=stream.toBlob('application/pdf');
			resolve(blob);
		});
	});
	return blob;
}

export function pages(pages:HTMLElement|HTMLElement[]){
	if(!Array.isArray(pages))
		pages=[pages];

	pages=pages.filter(page=>{
		const style=getComputedStyle(page);
		return style.visibility==='visible' && style.display!=='none';
	});
	
	if(pages.length===0)
		return null;

	const converter=new Converter();
	return converter.buildPageInfo(pages);
}

export function ready(){
	return !!(blobStream.blobStream && pdfkit.PDFDocument);
}
