import { Options } from "highcharts";
import * as Highcharts from 'highcharts/highstock';

declare module 'highcharts' {
  interface Options {
    datasetIds?: [];
    designBurden?:[]
  }
}

export function generateChartOptions(responseData = []) {
  let options = []
  if (Array.isArray(responseData)) {
    responseData.forEach((graph,i: number) => {
      options = [...options, generateSingleChartOptions(graph, i)];
    });
  }
  return options
}

export function generateSingleChartOptions(responseData, indexNumber: number): Highcharts.Options {
  const { title, chart, xAxis, yAxis, series, goalSeriesData, oversizeSeriesData, datasetId, designBurdens, unitAbbreviation, isAverage, currencySymbol } = responseData;

  let minWidth:number = 500;
  xAxis.categories.length > 10 ? minWidth = 10000 : minWidth;

  const seriesOptions = getSeriesOptions(chart,series);
  chart['indexNumber'] = indexNumber
  chart['showChart'] = true
  let trendlineSeriesOptions: Highcharts.SeriesOptionsType[] = [];

  if (chart.isFragmentSizeRangeChart) {
      seriesOptions.forEach((seriesOption: Highcharts.SeriesOptionsType, index) => {
          const seriesName = series[index].name;
              if ( index === 0 && oversizeSeriesData?.data?.length) {
                pushTrendline('Oversize ' + seriesName , oversizeSeriesData.data, trendlineSeriesOptions, 'dash', '#06402B');
              }
              else if (index == series?.length - 1) {
                pushTrendline(seriesName, series[index].data, trendlineSeriesOptions, 'dash');
                if (goalSeriesData.data.length) {
                    pushTrendline(seriesName + ' Goal', goalSeriesData.data, trendlineSeriesOptions, 'dash', 'lightGreen');
                  }
              }
      });
  }
  
  let xAxisLabels = {};
  if (title.text == "Explosive Cost Per Ton by Site" && chart.type === 'column') {
    xAxisLabels = {
      rotation: -45
    };
  }

  const sumText = series
  .map(data => {
    const nonZeroData = data.data.filter(value => value !== 0);
    const sum = nonZeroData.reduce((acc, value) => acc + value, 0);
    
    let result = '';
    if (isAverage) {
      const average = sum / nonZeroData.length || 0;  
      result = data.name?.toLowerCase()?.includes('average') 
        ? `${data.name}: ${average.toFixed(2)} ${unitAbbreviation}`
        : `Average ${data.name}: ${average.toFixed(2)} ${unitAbbreviation}`;
    } else {
      result = data.name?.toLowerCase()?.includes('total') 
        ? `${data.name}: ${sum.toFixed(2)} ${unitAbbreviation}`
        : `Total ${data.name}: ${sum.toFixed(2)} ${unitAbbreviation}`;
    }
    
    return result;
  })
  .filter(Boolean)
  .join(", ");

  const subTitleText = sumText;

  const chartOptions: Highcharts.Options = {
	showChart: true,
	chart: {
      type: chart.type,
      style: {
        fontFamily: 'Lato'
      },
      scrollablePlotArea: {
        scrollPositionX: 1,
        opacity:1
      },
      zooming: {
        type: chart.zoomType
      },
    },
    tooltip: {
      formatter: function () {
        if (chart.isFragmentSizeRangeChart) {
          return `
            <p>
              <span width='20' height='20' style="font-size:16px;color:${this.series.color}">●</span>
              <span><strong>${this.series.name}</strong>: ${this.y}%</span>
            </p>
          `;
        }
        else {
          return `
          <p>
            <span>${this.x}</span>
            <span width='20' height='20' style="font-size:16px;color:${this.series.color}">●</span>
            <span><strong>${this.series.name}</strong>: ${this.y}${chart.isOversizePercent || chart.isFinesPercent ? '%' : ''}</span>
          </p>
        `;
        }
      },
      style: {
        color: '#333333',
        cursor: 'default',
        fontSize: '1.5em'
      }
    },
    title: {
      text: title.text,
      align: 'left'
    },
    legend: {
      layout: 'horizontal',
      align: 'center',
      verticalAlign: 'bottom',
      maxHeight: 40,
    },
    xAxis: {
      categories: xAxis?.categories,
      title: {
        text: xAxis?.title.text,
        style: {
          fontSize: '1rem'
        }
      },
      labels: {
        rotation: 30,
        style: {
          fontSize: '1rem',
          distance: '0px',
          width: 80,
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        },     
      },
    },
    yAxis: {
      title: {
        text: currencySymbol ? yAxis?.title.text + ` (${currencySymbol})` :  yAxis?.title.text,
        style: {
          fontSize: '1rem'
        },
      },
      labels: {
        style: {
          fontSize: responseData?.isDrillAndBlastCosts ? '1rem' : '.8rem'
        }
      },
      tickInterval : chart.isFragmentSizeRangeChart ? 20 : ''
    },
    plotOptions: {
      series:{
        minPointLength: 3
      },
      pie:{
        dataLabels: {
          format: '{point.percentage:.1f}%',
        }
      },
    },  
    credits: {
      enabled: false
    },
    exporting: {
      enabled: true,
      buttons: {
        contextButton: {
          menuItems: chart.isFragmentSizeRangeChart ? 
            ["viewFullscreen", 
            "downloadCSV", 
            "downloadXLS"] : 
            [
            "viewFullscreen", 
            "downloadCSV", 
            "downloadXLS", 
              {
                text: 'View Table Data',
                onclick: () => {
                  const highCharts = Highcharts.charts.filter(chart=> chart );
                  chartOptions['showChart'] = !chartOptions['showChart'];
                  const chartContainer = document.getElementById(`chart-container-${chart.indexNumber}`) as HTMLElement;
                  const chartData = highCharts[chart.indexNumber];
                  chart.showChart = !chart.showChart;
                  if (!chartContainer.classList.contains('hide-container')) {
                    chartData?.viewData();
                    
                    // Create a close button
                    const button = document.createElement("button");
                    button.classList.add("close-icon");
                    button.setAttribute("title", "Close");
                    
                    // Create a mat-icon for close button
                    const matIcon = document.createElement("mat-icon");
                    matIcon.setAttribute("role", "img");
                    matIcon.classList.add("mat-icon", "notranslate", "material-icons-outlined", "mat-icon-no-color");
                    matIcon.setAttribute("aria-hidden", "true");
                    matIcon.setAttribute("data-mat-icon-type", "font");
                    matIcon.textContent = "close";  
                    // Append the mat-icon to the button
                    button.appendChild(matIcon);
                    const tableContainer = chartContainer.parentNode.children[1];
                    tableContainer?.prepend(button);
                    tableContainer?.classList.add('show-table');
                    
                    const table = tableContainer?.children[1];
                    var parentNode = table?.parentNode;
                    var element = document.createElement('div');
                    element.classList.add('graph-data');
                    parentNode.replaceChild(element, table);
                    element.appendChild(table);
                    
                    // EventListener on the Close button
                    button.addEventListener("click", function() {
                      const chartContainer = document.getElementById(`chart-container-${chart.indexNumber}`) as HTMLElement;
                      chartContainer.classList.remove('hide-container');
                      const highCharts = Highcharts?.charts?.filter(chart=> chart);
                      const selectedChart = highCharts[chart.indexNumber] ;
                      selectedChart.hideData();
                      chartContainer.parentNode.children[1]?.classList.remove('show-table');
                    });
                    chartContainer.classList.add('hide-container');
                  } 
                },
              }
            ],
        },
      },
    },
    navigation: {
      menuItemStyle : {
        fontSize: '15px'
      }
    },
    subtitle: {
      text: chart.isFragmentSizeRangeChart ? '' : subTitleText,
      align: 'left',
      style: {
        fontSize: '10px',
        fontWeight: 'bold'
      }
    },
	series: [...seriesOptions, ...trendlineSeriesOptions],
	accessibility: {
		enabled: true
	},
    datasetIds: chart.isDesignBurdenGraph 
    ? datasetId
    : (chart.isFragmentSizeRangeChart && goalSeriesData?.datasetIds?.length 
        ? goalSeriesData.datasetIds 
        : []),
    designBurden: designBurdens
}  as Options;

if (minWidth > 500) {
	chartOptions.navigator = {
		enabled: true,
		height: 30,
		margin: 5,
		xAxis: {
			labels: {
				enabled: false
			}
		}
	};
	chartOptions.scrollbar = {
		enabled: true,
		barBackgroundColor: '#5271ff',
	};
} else {
	chartOptions.navigator = {
		enabled: false
	};
}

if (chart.isFragmentSizeRangeChart) {
    chartOptions.plotOptions = {
        ...chartOptions.plotOptions,
        column: {
            stacking: 'normal',
        },
    };
}

	return chartOptions;
}

export function getSeriesOptions(chart,series) {
  let seriesOptions:[] = [];
    
  if (chart.type === 'pie') {
    seriesOptions = series.map(series => ({
        type: chart.type,
        innerSize:'50%',
        name:"site",
        data: series.data.map(data => ({
            name: data.name,
            y: data.y
        })),
      showInLegend: true,
    }));
  }
  else if(chart.type === "zoomType") { 
    seriesOptions = series.map((series) => ({
      type: series.type, 
      name: series.name,
      data: series.data,
    }));
  } else {
	seriesOptions =  series.map((seriesItem, index) => ({
		name: seriesItem.name,
		data: seriesItem.data,
		color: chart.isFragmentSizeRangeChart ? seriesItem.color : undefined
	}));
  }
  return seriesOptions;
}

function pushTrendline(seriesName: string, data: number[], trendlineSeriesOptions: any[], trendDashStyle: string,trendlineColor?: string) {
    trendlineSeriesOptions.push({
        type: 'line',
        name: seriesName + ' Trendline',
        marker: {
            enabled: false
        },
        dashStyle: trendDashStyle,
        data: data,
        color: trendlineColor || null,
        zIndex: -1
    });
}