<ng-container *ngIf="isLoading">
	<div class="loading-logo">
		<mat-spinner [matTooltip]="'Loading Costing Data'"></mat-spinner>
	</div>
</ng-container>
<div class="contents" *ngIf="!isLoading">
	<div class="explosives-container">
		<div class="header">
			Costing Details
		</div>
		<div class="main-content">
			<div class="selected-site">
				<mat-form-field appearance="outline" class="half-width">
					<mat-label>Select Site</mat-label>
					<input
						type="text"
						matInput
						[matAutocomplete]="siteOptions"
						[formControl]="siteControl"
						(input)="onSiteInput($event)"
					/>
					<mat-autocomplete #siteOptions="matAutocomplete" (optionSelected)="onSiteSelect($event)">
						<mat-option *ngFor="let site of filteredSites" [value]="site">
							{{ site.name }}
						</mat-option>
					</mat-autocomplete>
				</mat-form-field>
			</div>
			<div class="no-products" *ngIf="!isSiteSelected">
				Please Select the Site
			</div>
			<div class="no-products" *ngIf="isSiteSelected && isAllDataEmpty">
				No Products Available
			</div>
			<div class="costing" *ngIf="isSiteSelected && !isAllDataEmpty">
				<div class="costing-content">
					<form [formGroup]="productForm">
						<table class="decks half-width">
							<tbody>
								<ng-container formArrayName="explosiveCost">
									<tr>
										<div class="label-above">
											{{ tableData.explosiveCost.value }}
										</div>
									</tr>
									<tr
										*ngFor="let costGroup of explosiveCostRows.controls; let i = index"
										[formGroupName]="i"
										class="productData"
									>
										<td>
											<mat-form-field class="compact" appearance="outline">
												<mat-label>Select a Brand</mat-label>
												<mat-select
													formControlName="brand"
													(selectionChange)="productSelect(i, 'explosiveCost')"
												>
													<mat-option
														*ngFor="let product of getUniqueValues(tableData.explosiveCost.data)"
														[value]="product?.brand"
													>
														{{ product?.brand }}
													</mat-option>
												</mat-select>
											</mat-form-field>
										</td>
										<td>
											<mat-form-field class="compact" appearance="outline">
												<mat-label>Select a Product</mat-label>
												<mat-select
													formControlName="product"
												>
													<mat-option
														*ngFor="let product of getFilterValues(tableData.explosiveCost.data, 'explosiveCost', i)"
														[value]="product.name"
													>
														{{ product.name }}
													</mat-option>
												</mat-select>
											</mat-form-field>
										</td>
										<td>
											<mat-form-field class="compact" appearance="outline">
												<mat-label>Currency</mat-label>
												<mat-select
													formControlName="currency"
												>
													<mat-option
														*ngFor="let currency of currencyData | keyValueNoSort"
														[value]="currency?.key"
													>
														{{ currency?.value }}
													</mat-option>
												</mat-select>
											</mat-form-field>
										</td>
										<td>
											<mat-form-field class="compact" appearance="outline">
												<mat-label>
													{{
														'Cost per' | UnitDisplayPipe: userUnitMeasurement : tableData.explosiveCost.valueUnit
													}}
												</mat-label>
												<input
													matInput
													min="0"
													formControlName="cost"
													type="number"
												/>
											</mat-form-field>
										</td>
										<!-- Remove button column -->
										<td>
											<div class="btn-close">
												<button
													mat-icon-button
													matTooltip="Delete Row"
													(click)="removeRow('explosiveCost', i)"
												>
													<mat-icon>close</mat-icon>
												</button>
											</div>
										</td>
									</tr>
								</ng-container>
								<tr>
									<div class="btn-add">
										<button
											mat-icon-button
											matTooltip="Add a New Row"
											(click)="addRow('explosiveCost')"
										>
											<mat-icon>add</mat-icon>
										</button>
									</div>
								</tr>

								<!-- Booster Cost Section -->
								<tr>
									<div class="label-above">
										{{ tableData.boosterCost.value }}
									</div>
								</tr>
								<ng-container formArrayName="boosterCost">
									<tr
										*ngFor="let costGroup of boosterCostRows.controls; let i = index"
										[formGroupName]="i"
										class="productData"
									>
										<td>
											<mat-form-field class="compact" appearance="outline">
												<mat-label>Select a Brand</mat-label>
												<mat-select
													formControlName="brand"
													(selectionChange)="productSelect(i, 'boosterCost')"
												>
													<mat-option
														*ngFor="let product of getUniqueValues(tableData.boosterCost.data)"
														[value]="product?.brand"
													>
														{{ product?.brand }}
													</mat-option>
												</mat-select>
											</mat-form-field>
										</td>
										<td>
											<mat-form-field class="compact" appearance="outline">
												<mat-label>Select a Product</mat-label>
												<mat-select
													formControlName="product"
												>
													<mat-option
														*ngFor="let product of getFilterValues(tableData.boosterCost.data, 'boosterCost', i)"
														[value]="product.name"
													>
														{{ product.name }}
													</mat-option>
												</mat-select>
											</mat-form-field>
										</td>
										<td>
											<mat-form-field class="compact" appearance="outline">
												<mat-label>Currency</mat-label>
												<mat-select
													formControlName="currency"
												>
													<mat-option
														*ngFor="let currency of currencyData | keyValueNoSort"
														[value]="currency?.key"
													>
														{{ currency?.value }}
													</mat-option>
												</mat-select>
											</mat-form-field>
										</td>
										<td>
											<mat-form-field class="compact" appearance="outline">
												<mat-label>
													{{
														'Cost per' | UnitDisplayPipe: userUnitMeasurement : tableData.boosterCost.valueUnit
													}}
												</mat-label>
												<input
													matInput
													min="0"
													formControlName="cost"
													type="number"
												/>
											</mat-form-field>
										</td>
										<!-- Remove button column -->
										<td>
											<div class="btn-close">
												<button
													mat-icon-button
													matTooltip="Delete Row"
													(click)="removeRow('boosterCost', i)"
												>
													<mat-icon>close</mat-icon>
												</button>
											</div>
										</td>
									</tr>
								</ng-container>
								<tr>
									<div class="btn-add">
										<button
											mat-icon-button
											matTooltip="Add a New Row"
											(click)="addRow('boosterCost')"
										>
											<mat-icon>add</mat-icon>
										</button>
									</div>
								</tr>

								<!-- Detonator Cost Section -->
								<tr>
									<div class="label-above">
										{{ tableData.detonatorCost.value }}
									</div>
								</tr>
								<ng-container formArrayName="detonatorCost" class="productData">
									<tr
										*ngFor="let costGroup of detonatorCostRows.controls; let i = index"
										[formGroupName]="i"
										class="productData"
									>
										<td>
											<mat-form-field class="compact" appearance="outline">
												<mat-label>Select a Brand</mat-label>
												<mat-select
													formControlName="brand"
													(selectionChange)="productSelect(i, 'detonatorCost')"
												>
													<mat-option
														*ngFor="let product of getUniqueValues(tableData.detonatorCost.data)"
														[value]="product?.brand"
													>
														{{ product?.brand }}
													</mat-option>
												</mat-select>
											</mat-form-field>
										</td>
										<td>
											<mat-form-field class="compact" appearance="outline">
												<mat-label>Select a Product</mat-label>
												<mat-select
													formControlName="product"
												>
													<mat-option
														*ngFor="let product of getFilterValues(tableData.detonatorCost.data, 'detonatorCost', i)"
														[value]="product.name"
													>
														{{ product.name }}
													</mat-option>
												</mat-select>
											</mat-form-field>
										</td>
										<td>
											<mat-form-field class="compact" appearance="outline">
												<mat-label>Currency</mat-label>
												<mat-select
													formControlName="currency"
												>
													<mat-option
														*ngFor="let currency of currencyData | keyValueNoSort"
														[value]="currency?.key"
													>
														{{ currency?.value }}
													</mat-option>
												</mat-select>
											</mat-form-field>
										</td>
										<td>
											<mat-form-field class="compact" appearance="outline">
												<mat-label>
													Cost per (unit/cap)
												</mat-label>
												<input
													matInput
													min="0"
													formControlName="cost"
													type="number"
												/>
											</mat-form-field>
										</td>
										<!-- Remove button column -->
										<td>
											<div class="btn-close">
												<button
													mat-icon-button
													matTooltip="Delete Row"
													(click)="removeRow('detonatorCost', i)"
												>
													<mat-icon>close</mat-icon>
												</button>
											</div>
										</td>
									</tr>
								</ng-container>
								<tr>
									<div class="btn-add">
										<button
											mat-icon-button
											matTooltip="Add a New Row"
											(click)="addRow('detonatorCost')"
										>
											<mat-icon>add</mat-icon>
										</button>
									</div>
								</tr>
								<tr>
									<div class="label-above">
										Drilling Cost
									</div>
								</tr>
								<tr formGroupName="drillingCost" class="productData">
									<td>
										<mat-form-field class="compact" appearance="outline">
											<mat-label>
												{{ 'Cost per' | UnitDisplayPipe: userUnitMeasurement : 'distance' }}
											</mat-label>
											<input
												matInput
												min="0"
												formControlName="cost"
												type="number"
											/>
										</mat-form-field>
									</td>
									<td>
										<mat-form-field class="compact" appearance="outline">
											<mat-label>Currency</mat-label>
											<mat-select formControlName="currency">
												<mat-option
													*ngFor="let currency of currencyData | keyValueNoSort"
													[value]="currency?.key"
												>
													{{ currency?.value }}
												</mat-option>
											</mat-select>
										</mat-form-field>
									</td>
								</tr>
								<tr>
									<div class="label-above">
										Secondary Breakage Cost
									</div>
								</tr>
								<tr formGroupName="secondaryBreakageCost" class="productData">
									<td>
										<mat-form-field class="compact" appearance="outline">
											<mat-label>Cost per (ton)</mat-label>
											<input
												matInput
												min="0"
												formControlName="cost"
												type="number"
											/>
										</mat-form-field>
									</td>
									<td>
										<mat-form-field class="compact" appearance="outline">
											<mat-label>Currency</mat-label>
											<mat-select formControlName="currency">
												<mat-option
													*ngFor="let currency of currencyData | keyValueNoSort"
													[value]="currency?.key"
												>
													{{ currency?.value }}
												</mat-option>
											</mat-select>
										</mat-form-field>
									</td>
								</tr>
								<tr>
									<div class="label-above">
										Crusher Power Consumption Cost
									</div>
								</tr>
								<tr formGroupName="crusherPowerConsumptionCost" class="productData">
									<td>
										<mat-form-field class="compact" appearance="outline">
											<mat-label>Cost per (unit)</mat-label>
											<input
												matInput
												min="0"
												formControlName="cost"
												type="number"
											/>
										</mat-form-field>
									</td>
									<td>
										<mat-form-field class="compact" appearance="outline">
											<mat-label>Currency</mat-label>
											<mat-select formControlName="currency">
												<mat-option
													*ngFor="let currency of currencyData | keyValueNoSort"
													[value]="currency?.key"
												>
													{{ currency?.value }}
												</mat-option>
											</mat-select>
										</mat-form-field>
									</td>
								</tr>
							</tbody>
						</table>
					</form>
				</div>
			</div>
		</div>
	</div>
	<div class="actions">
		<button mat-button [mat-dialog-close]="null" class="cancel-btn">
			Cancel
		</button>
		<button
			mat-raised-button
			color="primary"
			class="update-btn"
			[disabled]="productForm.invalid || !isSiteSelected || isAllDataEmpty"
			(click)="saveCostingData()"
		>
			Save
		</button>
	</div>
</div>
