import { Component } from '@angular/core';
import { ChartDataService } from '@app/backend';

@Component({
	selector: 'app-fragmentation-dashboard',
	templateUrl: './fragmentation-dashboard.component.html',
	styleUrls: ['./fragmentation-dashboard.component.scss']
})
export class FragmentationDashboardComponent {
	private selectedTab: string = 'PF & Tons';
	constructor(private readonly chartDataService: ChartDataService) {}

	ngOnInit(): void {
		this.chartDataService.selectedTab$.next(this.selectedTab);
	}

	public onSequenceChangeEvent(ev) {
		this.chartDataService.selectedTab$.next(ev.tab?.textLabel);
	}
}
