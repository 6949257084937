import { MultiMap } from "@lib/multi-map";
import { getRectStr } from "./shared";
import { ElementMeta } from "./element-meta";
import { math } from "@lib/math";

export class ElementMetaMap{
	private readonly map=new Map<HTMLElement|SVGSVGElement,ElementMeta>();

	public pull(ele:HTMLElement|SVGElement, zIndex:number):void{
		if(ele.classList.contains('no-pdf'))
			return null;

		if(ele instanceof HTMLElement || ele instanceof SVGSVGElement){
			const meta=ElementMeta.pull(ele,zIndex);
			if(meta)
				this.map.set(ele,meta);
		}

		for(let i=0;i<ele.children.length;++i){
			const child=ele.children.item(i);
			if(child instanceof HTMLElement || child instanceof SVGElement){
				this.pull(child,zIndex);
			}
		}
	}

	public get(ele:HTMLElement|SVGSVGElement){
		return this.map.get(ele);
	}

	public delete(ele:HTMLElement|SVGSVGElement){
		return this.map.delete(ele);
	}

	public removeTransforms(){
		for(const [element,meta] of this.map){
			if(meta.styles.transform!=='none'){
				if(element.style.transform)
					meta.originalTransform=element.style.transform;
				else
					meta.originalTransform='';
				element.style.transform='none';
			}
		}
	}

	public restoreTransforms(){
		for(const [element,meta] of this.map){
			if(meta.originalTransform!==undefined){
				element.style.transform=meta.originalTransform;
			}
		}
	}

	public getElementRects(
		pageRect:math.Box2,
		inverseScale:number,
	){
		for(const [element,meta] of this.map){
			meta.rect=math.Box2.fromDOMRect(element.getBoundingClientRect());
			meta.rect.min.sub(pageRect.min);
			meta.rect.max.sub(pageRect.min);
			meta.rect.min.multiplyScalar(inverseScale);
			meta.rect.max.multiplyScalar(inverseScale);
		}
	}

	public getCanvases(){
		const canvasList=new MultiMap<string,HTMLCanvasElement>();
		let canvasElements=[...this.map.keys()].filter((ele):ele is HTMLCanvasElement=>ele instanceof HTMLCanvasElement);
		for(const ele of canvasElements){
			const rect=this.map.get(ele)?.rect;
			const rectStr=getRectStr(rect);
			if(!!rect) {
				canvasList.add(rectStr,ele);
			}
		}
		return canvasList;
	}

	public getFlatNodes(node: Node, out: Node[] = []) {
		for (const child of Array.from(node.childNodes)) { // Convert NodeList to an array
			if (child instanceof HTMLElement) {
				const meta = this.get(child);
				if (!meta) continue;
				if (meta.styles.display === 'contents') {
					this.getFlatNodes(child, out);
					continue;
				}
			}
			out.push(child);
		}
		return out;
	}
	
}
