import { Component, ElementRef, OnDestroy, OnInit } from '@angular/core';
import { SiteService } from '@app/app-state/site.service';
import { UserService } from '@app/app-state/user.service';
import { ChartDataService } from '@app/backend/chart-data.service';
import { GenerateChartDataService } from '@app/backend/generate-chart-data.service';
import { ChartDataInterface, DrillDetailsInterface } from "@app/models/chart-data.model";
import * as charts from '@utilities/highchart-objectify';
import * as Highcharts from 'highcharts/highstock';
import { Subscription } from 'rxjs';
import { contentTypeByExtension } from '@utilities/html-to-pdf/content-types';
import { MatDialog } from '@angular/material/dialog';
import { GraphReportComponent } from '@app/gui/report/graph-report/graph-report.component';

@Component({
	selector: 'app-hole-count-and-length',
	templateUrl: './hole-count-and-length.component.html',
	styleUrls: ['./hole-count-and-length.component.scss']
})
export class HoleCountAndLengthComponent implements OnInit, OnDestroy {

	public Highcharts: typeof Highcharts = Highcharts; // required

	public chartOptions = [] // required
	private subscriptions: Subscription = new Subscription();

	public loading:boolean;

	private domainId: number;
	private subdomainId: number;
	private siteId: number;
	private startDate: string;
	private endDate: string;
	private selectedTimeline: string
	private holeAndCount:ChartDataInterface[] = [];

	constructor(
		private chartDataService:ChartDataService, 
		private generateChartDataService:GenerateChartDataService,
		private siteService: SiteService,
		private userService: UserService,
		private readonly dialog: MatDialog,
	) {	}

	ngOnInit(): void {
		this.subscriptions.add(this.chartDataService.loading$.subscribe(loading => {
			this.loading = loading;
		}));

		this.subscriptions.add(this.chartDataService.drillDetails$.subscribe((res) => {
			this.triggerLoadData(res);
		}));
		
		this.subscriptions.add(this.siteService.domainId$.subscribe(domainId => {
			this.domainId = domainId;
			if (this.domainId) {
				this.getReport();;
			}
		}));

		this.subscriptions.add(this.siteService.subDomainId$.subscribe(subdomainId => {
			this.subdomainId = subdomainId;
			this.triggerLoadData();
		}));

		this.subscriptions.add(this.siteService.siteId$.subscribe(siteId => {
			this.siteId = siteId;
			this.triggerLoadData();
		}));

		this.subscriptions.add(this.siteService.startDate$.subscribe(startDate => {
			this.startDate = startDate;
			this.triggerLoadData();
		}));

		this.subscriptions.add(this.siteService.endDate$.subscribe(endDate => {
			this.endDate = endDate;
			this.triggerLoadData();
		}));
		
		this.subscriptions.add(this.siteService.selectedTimeline$.subscribe(selectedTimeline => {
			this.selectedTimeline = selectedTimeline;
			this.triggerLoadData();
		})); 

		this.subscriptions.add(this.userService.userUnitMeasurement$.subscribe(() => {
			this.triggerLoadData();
		}));
	}

	private async getReport() {
		this.subscriptions.add(this.chartDataService.getCacheDrillDetails().subscribe((res) => {
			this.triggerLoadData(res);
		}));
	}

	private loadData = (chartData: ChartDataInterface[])=> {
		this.holeAndCount = chartData;
		const data:DrillDetailsInterface = this.generateChartDataService.generateChartData(this.holeAndCount);
		for (const key in data?.holeCountAndLength) {
			this.chartOptions = charts.generateChartOptions(data.holeCountAndLength[key]);
			this.chartDataService.exportedGraphData$.next(data.holeCountAndLength[key]);
		}
	}

	private triggerLoadData(data? : ChartDataInterface[]) {
		const originalDrillAndBalstData = data || this.generateChartDataService.originalDrillAndBalstData;
		this.holeAndCount = this.generateChartDataService.getFilteredData(
			originalDrillAndBalstData,
			this.domainId,
			this.subdomainId,
			this.siteId,
			this.startDate,
			this.endDate,
			this.selectedTimeline    
		);
		this.loadData(this.holeAndCount);
	}

	ngOnDestroy() {
		this.subscriptions.unsubscribe();
	}

	public pdfDownload() {
		this.dialog.open(GraphReportComponent, {
			width: '100vw', 
			height: '100vh',
			maxWidth: '100vw',
			panelClass: 'full-screen-dialog',
			disableClose: true,
		});
	}
}
