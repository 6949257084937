import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';

import { SiteService } from '@app/app-state/site.service';
import { UserService } from '@app/app-state/user.service';
import * as backend from '@app/backend';
import { ChartDataService } from '@app/backend/chart-data.service';
import { GenerateChartDataService } from '@app/backend/generate-chart-data.service';
import { ChartDataInterface, designBurdenParams } from '@app/models/chart-data.model';
import { Subscription } from 'rxjs';

@Component({
	selector: 'app-burden-and-spacing-table',
	templateUrl: './burden-and-spacing-table.component.html',
	styleUrls: ['./burden-and-spacing-table.component.scss']
})
export class BurdenAndSpacingTableComponent implements OnInit {
	@ViewChild(MatPaginator) paginator: MatPaginator;
	displayedColumns: string[] = ['project','averageBurden', 'designBurden'];
	dataSource: MatTableDataSource<any> = new MatTableDataSource([]);
	datasetMap: { [name: string]: number } = {};

	public loading:boolean;
	public isInputChanged:boolean = false;
	public datasetId:[];
	private subscriptions: Subscription = new Subscription();
	private domainId: number;
	private subdomainId: number;
	private siteId: number;
	private startDate: string;
	private endDate: string;
	private selectedTimeline: string;
	private burdenAndSpacingData:ChartDataInterface[] = [];
	public userUnitMeasurement;
	constructor(
		private generateChartDataService:GenerateChartDataService,
		private snackBar: MatSnackBar,
		private chartDataService: ChartDataService,
		private siteService: SiteService,
		private userService: UserService
	) {}

	ngOnInit(): void {
		this.subscriptions.add(this.chartDataService.loading$.subscribe(loading => {
			this.loading = loading;
		}));

		this.subscriptions.add(this.chartDataService.drillDetails$.subscribe((res) => {
			this.triggerLoadData(res);
		}));
		
		this.subscriptions.add(this.siteService.domainId$.subscribe(domainId => {
			this.domainId = domainId;
			if (this.domainId) {
				this.getReport();
			}
		}));

		this.subscriptions.add(this.siteService.subDomainId$.subscribe(subdomainId => {
			this.subdomainId = subdomainId;
			this.triggerLoadData();
		}));

		this.subscriptions.add(this.siteService.siteId$.subscribe(siteId => {
			this.siteId = siteId;
			this.triggerLoadData();
		}));

		this.subscriptions.add(this.siteService.startDate$.subscribe(startDate => {
			this.startDate = startDate;
			this.triggerLoadData();
		}));
		
		this.subscriptions.add(this.siteService.endDate$.subscribe(endDate => {
			this.endDate = endDate;
			this.triggerLoadData();
		}));
		
		this.subscriptions.add(this.siteService.selectedTimeline$.subscribe(selectedTimeline => {
			this.selectedTimeline = selectedTimeline;
			this.triggerLoadData();
		}));

		this.subscriptions.add(this.userService.userUnitMeasurement$.subscribe((measurement) => {
			this.triggerLoadData();
			this.userUnitMeasurement = measurement;
		}));

		this.generateChartDataService.chartOptions$.subscribe(options => {
			options.forEach(option => {
				if (option.chart && option.chart.isDesignBurdenGraph) {
					this.getAverageBurden(option);
				}
			});
		});
	}

	private triggerLoadData(data? : ChartDataInterface[]) {
		const originalDrillAndBalstData = data || this.generateChartDataService.originalDrillAndBalstData;
		this.burdenAndSpacingData = this.generateChartDataService.getFilteredData(
			originalDrillAndBalstData,
			this.domainId,
			this.subdomainId,
			this.siteId,
			this.startDate,
			this.endDate,
			this.selectedTimeline    
		);
		this.loadData(this.burdenAndSpacingData);
	}

	private async getReport() {
		this.subscriptions.add(this.chartDataService.getCacheDrillDetails().subscribe((res) => {
			this.triggerLoadData(res);
		}));
	}

	private loadData = (chartData: any)=> {
		this.burdenAndSpacingData = chartData;
		this.generateChartDataService.generateChartData(this.burdenAndSpacingData);
	}

	private async getAverageBurden(options) {
		const { series, datasetId, xAxis, designBurdens } = options;

		if(series) {
			const data = series[0].data;
			const categories = xAxis.categories;
			
			categories.forEach((category, index) => {
				this.datasetMap[category] = datasetId[index];
			});

			const formattedData = data.map((value, index) => ({
				project:categories[index],
				averageBurden: value,
				designBurden:designBurdens[index],
				datasetId: this.datasetMap[categories[index]]
			}));
		
			this.dataSource.data = formattedData;
		
			if (this.paginator) {
				this.dataSource.paginator = this.paginator;
			}
		}
	}
	

	public onInputChange() {
		this.isInputChanged = true;
	}

	public addDesignBurdens() {
		if(this.isInputChanged) {
			const updatedData = this.dataSource.data;
			const designBurdenParams: designBurdenParams = {
				designBurdenArray: updatedData.map(item => ({
					project: item.project,
					averageBurden: item.averageBurden,
					designBurden: item.designBurden,
					datasetId: this.datasetMap[item.project]
				}))
			};

			this.updateDesignBurdens(designBurdenParams);
			this.isInputChanged = false;
		}
		else {
			this.snackBar.open('Design burden not added/updated', ' ', { horizontalPosition: 'right', verticalPosition: 'top', duration: 3 * 1000 });
		}
	}

	private async updateDesignBurdens(designBurdenParams) {
		try {
			const response = await backend.updateDesignBurdens(designBurdenParams);
			this.dataSource.data = response.data;
			this.chartDataService.getAllDrillDetails();
			this.snackBar.open(response.message, ' ', { horizontalPosition: 'right', verticalPosition: 'top', duration: 3 * 1000 });
		} catch (error) {
			console.error("API Error", error);
		}
	}
}
