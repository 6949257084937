import { math } from '@lib/math';
import { fontAscents, getBaseline, getFont } from './fonts';
import { PageMeta } from './page-meta';
import { TextLine,elementToTextChild } from './pdf-html-text';
import { PdfRenderer } from './pdf-renderer';
import { fromPx } from './shared';

function getLineHeight(styles:CSSStyleDeclaration){
	if(styles.lineHeight==='normal'){
		let x=fromPx(styles.fontSize);
		x*=1.2;
		return x;
	}

	return fromPx(styles.lineHeight);
}

function getTextLines(rdr:PdfRenderer, page:PageMeta, container:HTMLElement, styles:CSSStyleDeclaration, containerRect:math.Vec2){
	// if(styles.writingMode==='vertical-rl')
	// 	containerRect={width:containerRect.height,height:containerRect.width};

	const nodes=page.meta.getFlatNodes(container);
	if(nodes.length===0)
		return [];

	let children=elementToTextChild(page,container,styles);

	if(children.length===0)
		return [];

	if(children.at(-1).text)
		children.at(-1).text=children.at(-1).text.replace(/\s+$/,'');

	for(const child of children)
		child.calcWidth(rdr);
	
	const font=getFont(styles);
	const lineHeight=math.max(font.size,getLineHeight(styles));
	const containerWidth=math.ceil(containerRect.width);
	let containerHeight=containerRect.height;
	const lines:TextLine[]=[new TextLine()];
	let x=0,y=0;
	for(const child of children){
		if(x+child.trimmedWidth>containerWidth){
			if(x===0){
				//this piece is bigger than the line, just leave it there on its own line
				lines.at(-1).append(rdr,child,x);
				y+=lineHeight;
				lines.push(new TextLine());
				continue;
			}

			if(containerHeight-y<1.25*lineHeight){
				//this piece should mathematically go to the next line but it seems like there are no more lines
				//browser sometimes cheats it seems when telling size to just tack it to the end of it anyways
				lines.at(-1).append(rdr,child,x);
				y+=lineHeight;
				lines.push(new TextLine());
				continue;
			}

			x=0;
			y+=lineHeight;
			lines.push(new TextLine());
		}

		lines.at(-1).append(rdr,child,x);
		x+=child.width;
	}

	if(lines.at(-1).isEmpty())
		lines.pop();
	return lines;
}

export function renderText(rdr:PdfRenderer, page:PageMeta, ele:HTMLElement, paddingRect:math.Box2){
	const styles=page.meta.get(ele).styles;
	//const inTableCell=ele instanceof HTMLTableCellElement;

	const shouldDebug=false;//styles.writingMode==='vertical-rl';

	const isVertical=styles.writingMode==='vertical-rl';
	if(isVertical){
		//FIXME
		// rdr.save();
		// rdr.translate(paddingRect.right,paddingRect.y);
		// rdr.rotate(90);
		paddingRect=math.Box2.from(0,0,paddingRect.size('y'),paddingRect.size('x'));
	}
	const lines=getTextLines(rdr,page,ele,styles,paddingRect.size());
	if(lines.length===0)
		return;

	const containerFontSize=fromPx(styles.fontSize);
	const font=getFont(styles);
	const lineHeight=math.max(font.size,getLineHeight(styles));//seriously need this max(), its what the browser does
	if(shouldDebug){
	}

	lines.reverse();
	let y=paddingRect.size('y');
	for(const line of lines){
		let x=paddingRect.min.x;
		if(styles.textAlign==='center')
			x=paddingRect.center('x')-line.width/2;
		else if(styles.textAlign==='right')
			x=paddingRect.max.x-line.width;
		for(const child of line){
			if(!child.hasText())
				continue;

			child.applyStyles(rdr);
			const baseline=getBaseline(containerFontSize,lineHeight,font,child.verticalAlign);
			rdr.drawText(
				child.text,
				x+child.x,
				paddingRect.min.y+y,
				baseline,
				true,
				false,
			);

		}
		y-=lineHeight;
	}
}
