import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { BehaviorSubject, combineLatest } from '@rxjs';
import { contentTypeByExtension } from '@utilities/html-to-pdf/content-types';
import * as htmlToPdf from '@utilities/html-to-pdf';
import { InReport, ReportService } from '../service.service';
import { ReportPageContainerComponent } from '../report-page-container/report-page-container.component';
import { iterate } from '@lib/iterate';
import { math } from '@lib/math';
import { SiteService } from '@app/app-state/site.service';
import { delay, Subscription } from 'rxjs';
import { ChartDataService } from '@app/backend';

@Component({
	selector: 'report',
	templateUrl: './report.component.html',
	styleUrls: ['./report.component.scss'],
	providers: [
		{
			provide: InReport,
			useFactory: ()=>true
		},
	],
})

export class ReportComponent {
	public isDownloading: boolean = false;
	public domainName: string;
	public subDomainName: string;
	public siteName: string;
	public selectedPageSize='LETTER';
	public readonly pageSizes=Object.keys(htmlToPdf.pageSizes);
	@Input() title='';

	@Input() get orientation(){return this.report.orientation;}
			 set orientation(v:'portrait'|'landscape'){this.report.orientation=v;}
	@Output() closeModal = new EventEmitter<boolean>();
	@Input() csv:()=>Promise<string>=null;
	@ViewChild('pageContainer') pageContainer:ReportPageContainerComponent;
	public zoom:ReportPageContainerComponent['zoom']='fit';
	public readonly zoomOptions=[
		['Fit','fit'],
		['Rows 2','rows2' ],
		...[...iterate.numbers(1,2,0.1,true)].map(v=>[math.round(v*100).toFixed(0)+'%',v])
	];
	private subscriptions: Subscription = new Subscription();
	selectedTab: string;
	
	constructor(private readonly host:ElementRef<HTMLElement>,
		public readonly report:ReportService,
		private readonly siteService: SiteService,
		private chartDataService: ChartDataService
	){
	const combinedSub = combineLatest([
			siteService.seletedDomainName$,
			siteService.seletedSubDomainName$,
			siteService.seletedSiteName$,
			chartDataService.selectedTab$
		]).pipe(delay(100))
		.subscribe(ids => {
			const [seletedDomainName, seletedSubDomainName, seletedSiteName, selectedTab] = ids;
			this.domainName = seletedDomainName;
			this.subDomainName = seletedSubDomainName;
			this.siteName = seletedSiteName;
			this.selectedTab = selectedTab;
		});

		this.subscriptions.add(combinedSub);
	}
	async download() {
		this.isDownloading = true;
		const progress$ = new BehaviorSubject<number>(0);
		const pages: HTMLElement[] = [];
		this.host.nativeElement.querySelectorAll<HTMLElement>('report-page').forEach(page => pages.push(page));
		const blob = await htmlToPdf.convert(pages, progress$);

		if(blob)
		this.downloadContent(this.filename('pdf'),blob,'application/pdf');
	}

	private filename(ex: string) {
		return this.cleanFilename(this.selectedTab,ex)
	}

	private cleanFilename(filename:string, ext:string='', spaceSub='-'){
		filename=filename.trim();
		filename=filename.replace(/&/g,' and ');
		filename=filename.replace(/\s+/g,spaceSub);
		filename=filename.replace(/[^a-zA-Z0-9_\-\.]+/g,'');
		filename=filename.replace(new RegExp(`[\\${spaceSub}]+`,'g'),spaceSub);

		if(ext!=='' && ext[0]!=='.')
		ext='.'+ext;
		return filename+ext;
	}

	private downloadContent(filename: string, contents:string|Blob|ArrayBuffer, contentType?:string){
		contentType??= contentTypeByExtension(filename) ?? 'text/plain';
		const link = document.createElement('a');
		link.download = filename;

		const blob = (contents instanceof Blob)?contents:new Blob([contents], { type: contentType });
		link.href = window.URL.createObjectURL(blob);
		document.body.appendChild(link);
		link.click();
		document.body.removeChild(link);
		this.isDownloading = false;
	};

	public close() {
		this.closeModal.emit(true)
	}

	ngOnDestroy() {
		this.subscriptions.unsubscribe();
	}

}
