import { Component } from '@angular/core';
import { reportRouteHost } from '@app/gui/report/report-route-props';
import { MatDialogRef } from '@angular/material/dialog';
import { ChartDataService } from '@app/backend';
import * as charts from '@utilities/highchart-objectify';

@Component({
	host: reportRouteHost,
	selector: 'app-graph-report',
	templateUrl: './graph-report.component.html',
	styleUrls: ['./graph-report.component.scss']
})
export class GraphReportComponent {
	public title: string;
	public chartOptions = [];
	
	constructor(
		private dialogRef: MatDialogRef<GraphReportComponent>,
		private chartDataService: ChartDataService,
	) {
		chartDataService.selectedTab$.subscribe(tab => this.title = tab);
	}

	public closeModal(ev) {
		this.dialogRef.close();
	}

	ngOnInit() {
		this.chartDataService.exportedGraphData$.subscribe(data=>{
			this.chartOptions = charts.generateChartOptions(data);
			if (this.chartOptions?.length > 0) {
				this.chartOptions.map(data => {
					data.title = {
						...data.title,
						style: {
							fontSize: '30px'
						}
					}
					data.subtitle.style.fontSize = '20px';
				})
			}
		})
	}
}
