import { Component } from '@angular/core';
import { environment } from '@environments/environment.base';

@Component({
	selector: 'report-page-footer',
	templateUrl: './report-page-footer.component.html',
	styleUrls: ['./report-page-footer.component.scss']
})
export class ReportPageFooterComponent {
	public readonly date = new Date();
	public readonly vendor: string = environment.vendor;
}
