
String.prototype.indent=function(this:string, indentation:string){
	if(this==='')
		return '';
	return this.split('\n').map(v=>`${indentation}${v}`).join('\n');	
}

String.prototype.matchCount=function(this:string, sv:string){
	let offset=0,count=0;
	while((offset=this.indexOf(sv,offset))>=0){
		offset+=sv.length;
		count++;
	}
	return count;
}
