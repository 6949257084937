
export function isValidNumber(v:number|unknown):v is number{
	return typeof(v)==='number' && !isNaN(v);
}

export function canBeValidNumber(v:any){
	return !isNaN(+v);
}

export function isFiniteNumber(v:number|unknown):v is number{
	return typeof(v)==='number' && !isNaN(v) && isFinite(v);
}

export function isInt(v:number|unknown):v is number{
	return typeof(v)==='number' && !isNaN(v) && Math.floor(v)===v;
}

//doesn't convert, returns undefined if its not a finite number
export function asFiniteNum(v:number|string|unknown):number|undefined{
	if(typeof(v)==='string')
		v=+v;
	if(isFiniteNumber(v))
		return v;
	return undefined;
}

//doesn't convert, returns undefined if its not an integer
export function asInt(v:number|string|unknown):number|undefined{
	if(typeof(v)==='string')
		v=+v;
	if(isInt(v))
		return +v;
	return undefined;
}

export function tryNum(v:any){
	if(typeof(v)==='string'){
		const n=parseFloat(v);
		if(!isNaN(n))
			return n;
	}
	return v;
}
