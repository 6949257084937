import { dom } from "@lib/dom";
import { math } from "@lib/math";
import { ElementMetaMap } from "./element-meta-map";
import { pageSizes } from "./page-sizes";

function ancesterWithClass<T extends string>(element:HTMLElement, classes:T[]){
	let checkElement=element;
	while(checkElement){
		for(const _class of classes){
			if(checkElement.classList.contains(_class))
				return _class;
		}
		if(checkElement.parentNode instanceof HTMLElement)
			checkElement=checkElement.parentNode;
	}
	return null;
}

export class PageMeta{
	public constructor(
		public readonly element:HTMLElement
	){
	}

	public rect:math.Box2;
	public size:string;
	public layout:'landscape'|'portrait';
	public dimensions:math.Vec2; //dimensions in printerPoints
	public ptsPerPx:number;// printerPoints/px
	public readonly meta=new ElementMetaMap();
	public canvasList:Map<string,Array<HTMLCanvasElement>>;

	public init(){
		const {element}=this;
		this.size=ancesterWithClass(element,Object.keys(pageSizes)) || 'LETTER';
		this.layout=ancesterWithClass(element,['portrait','landscape']) || 'portrait';
		this.rect=math.Box2.fromDOMRect(element.getBoundingClientRect());
		this.dimensions=math.Vec2.fromArray(pageSizes[this.size]);
		if(this.layout==='landscape')
			[this.dimensions.x,this.dimensions.y]=[this.dimensions.y,this.dimensions.x];
		this.ptsPerPx=this.dimensions.x/element.clientWidth;

		for(let i=0;i<element.children.length;++i){
			const child=element.children.item(i);
			if(child instanceof HTMLElement || child instanceof SVGElement)
				this.meta.pull(child,0);
		}
		const transform=dom.css.getTransform(element);
		const inverseScale=1/transform.a;

		this.meta.removeTransforms();
		this.meta.getElementRects(this.rect,inverseScale);
		this.canvasList=this.meta.getCanvases();
		this.meta.restoreTransforms();
		this.rect.max.sub(this.rect.min);
		this.rect.min.set(0,0);
		this.rect.max.multiplyScalar(inverseScale);
	}
}
