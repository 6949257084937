import { getPromise, postPromise } from "@app/backend";

export function getBlastProducts(subDomainId: number) {
	return getPromise(
		`api/v2/get_blast_products?subdomain_id=${subDomainId}`,
		(data) => data || null
	);
}

export function createCosting(costingDetails) {
	return postPromise(
		`api/dashboards/create_costing_details`,costingDetails,
		(data) => data || null
	);
}