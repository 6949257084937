import { Component, Input, OnInit } from "@angular/core";

@Component({
	selector: 'report-page-title',
	templateUrl: './report-page-title.component.html',
	styleUrls: ['./report-page-title.component.scss'],
})
export class ReportPageTitleComponent implements OnInit{
	@Input() title:string=null;
	
	public reporterName:string;
	public reporterTitle:string;
	public reportDate =  new Date();

	public constructor() {}

	ngOnInit() {}
}
