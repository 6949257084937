import { math } from '../../math';
import { arcToBezier } from './arc-to-bezier';

interface Renderer{
	closePath():void;
	moveTo(p:math.Vec2Like):void;
	bezierCurveTo(cp1:math.Vec2Like, cp2:math.Vec2Like, p:math.Vec2Like):void;
	lineTo(p:math.Vec2Like):void;
	quadraticCurveTo(cp:math.Vec2Like, p:math.Vec2Like):void;
}

interface Command{
	cmd:string;
	args:number[];
}

const cur=new math.Vec2(0,0);
const ctrp=new math.Vec2(0,0);//control point
const strp=new math.Vec2(0,0);//start point

function solveArc(rdr:Renderer, b:math.Vec2, coords:number[]){
	//const [rx, ry, rot, large, sweep, ex, ey]=coords;
	const r=math.Vec2.fromArray(coords,0);
	const rot=coords[2]*(Math.PI/180);
	const large=coords[3];
	const sweep=coords[4];
	const e=math.Vec2.fromArray(coords,5);
	const segs=arcToBezier(b, e, r, large, sweep, rot);

	for (let seg of segs){
		rdr.bezierCurveTo(seg.cp1,seg.cp2,seg.p);
	}
} // from Inkscape svgtopdf, thanks!


const runners:Record<string,(rdr:Renderer, a:number[])=>void>={
	M(rdr, a){
		cur.x=a[0];
		cur.y=a[1];
		ctrp.x=ctrp.y=null;
		strp.x=cur.x;
		strp.y=cur.y;
		rdr.moveTo(cur);
	},

	m(rdr, a){
		cur.x+=a[0];
		cur.y+=a[1];
		ctrp.x=ctrp.y=null;
		strp.x=cur.x;
		strp.y=cur.y;
		rdr.moveTo(cur);
	},

	C(rdr, a){
		const cp1=math.Vec2.fromArray(a,0);
		ctrp.x=a[2];
		ctrp.y=a[3];
		cur.x=a[4];
		cur.y=a[5];
		rdr.bezierCurveTo(cp1,ctrp.clone(),cur.clone());
	},

	c(rdr, a){
		const cp1=math.Vec2.fromArray(a,0).add(cur);
		ctrp.x=cur.x+a[2];
		ctrp.y=cur.y+a[3];
		cur.x+=a[4];
		cur.y+=a[5];
		rdr.bezierCurveTo(cp1,ctrp.clone(),cur.clone());
	},

	S(rdr, a){
		if(ctrp.x === null){
			ctrp.x=cur.x;
			ctrp.y=cur.y;
		}

		const cp1=cur.clone().sub(ctrp).add(cur);
		ctrp.x=a[0];
		ctrp.y=a[1];
		cur.x=a[2];
		cur.y=a[3];
		rdr.bezierCurveTo(cp1,ctrp.clone(),cur.clone());
	},

	s(rdr, a){
		if(ctrp.x === null){
			ctrp.x=cur.x;
			ctrp.y=cur.y;
		}

		const cp1=cur.clone().sub(ctrp).add(cur);
		ctrp.x=cur.x+a[0];
		ctrp.y=cur.y+a[1];
		cur.x+=a[2];
		cur.y+=a[3];
		rdr.bezierCurveTo(cp1,ctrp.clone(),cur.clone());
	},

	Q(rdr, a){
		ctrp.x=a[0];
		ctrp.y=a[1];
		cur.x=a[2];
		cur.y=a[3];
	},

	q(rdr, a){
		ctrp.x=cur.x+a[0];
		ctrp.y=cur.y+a[1];
		cur.x+=a[2];
		cur.y+=a[3];
		rdr.quadraticCurveTo(ctrp.clone(), cur.clone());
	},

	T(rdr, a){
		if(ctrp.x === null){
			ctrp.x=cur.x;
			ctrp.y=cur.y;
		} else {
			ctrp.x=cur.x-(ctrp.x-cur.x);
			ctrp.y=cur.y-(ctrp.y-cur.y);
		}

		const cp=ctrp.clone();
		ctrp.x=cur.x-(ctrp.x-cur.x);
		ctrp.y=cur.y-(ctrp.y-cur.y);
		cur.x=a[0];
		cur.y=a[1];
		rdr.quadraticCurveTo(cp, cur.clone());
	},

	t(rdr, a){
		if(ctrp.x === null){
			ctrp.x=cur.x;
			ctrp.y=cur.y;
		} else {
			ctrp.x=cur.x-(ctrp.x-cur.x);
			ctrp.y=cur.y-(ctrp.y-cur.y);
		}

		const cp=ctrp.clone();
		ctrp.x=cur.x-(ctrp.x-cur.x);
		ctrp.y=cur.y-(ctrp.y-cur.y);
		cur.x+=a[0];
		cur.y+=a[1];
		rdr.quadraticCurveTo(cp, cur.clone());
	},

	A(rdr, a){
		solveArc(rdr,cur,a);
		cur.x=a[5];
		cur.y=a[6];
	},

	a(rdr, a){
		a[5]+=cur.x;
		a[6]+=cur.y;
		solveArc(rdr,cur,a);
		cur.x=a[5];
		cur.y=a[6];
	},

	L(rdr, a){
		cur.x=a[0];
		cur.y=a[1];
		ctrp.x=ctrp.y=null;
		rdr.lineTo(cur.clone());
	},

	l(rdr, a){
		cur.x+=a[0];
		cur.y+=a[1];
		ctrp.x=ctrp.y=null;
		rdr.lineTo(cur.clone());
	},

	H(rdr, a){
		cur.x=a[0];
		ctrp.x=ctrp.y=null;
		rdr.lineTo(cur.clone());
	},

	h(rdr, a){
		cur.x+=a[0];
		ctrp.x=ctrp.y=null;
		rdr.lineTo(cur.clone());
	},

	V(rdr, a){
		cur.y=a[0];
		ctrp.x=ctrp.y=null;
		rdr.lineTo(cur.clone());
	},

	v(rdr, a){
		cur.y+=a[0];
		ctrp.x=ctrp.y=null;
		rdr.lineTo(cur.clone());
	},

	Z(rdr){
		rdr.closePath();
		cur.x=strp.x;
		cur.y=strp.y;
	},

	z(rdr){
		rdr.closePath();
		cur.x=strp.x;
		cur.y=strp.y;
	}

};

export class SvgPath{
	public constructor(
		private commands:Command[],
	){
	}

	public apply(rdr:Renderer){
		cur.x=0;
		cur.y=0;
		ctrp.x=0;
		ctrp.y=0;
		strp.x=0;
		strp.y=0;
		
		for (let i=0;i<this.commands.length;i++){
			const c=this.commands[i];
			if(typeof runners[c.cmd]==='function'){
				runners[c.cmd](rdr, c.args);
			}
		}
	}

}

export namespace SvgPath{
	const parameterCounts:Record<string,number>={
		A: 7,
		a: 7,
		C: 6,
		c: 6,
		H: 1,
		h: 1,
		L: 2,
		l: 2,
		M: 2,
		m: 2,
		Q: 4,
		q: 4,
		S: 4,
		s: 4,
		T: 2,
		t: 2,
		V: 1,
		v: 1,
		Z: 0,
		z: 0
	};


	export function parse(path:string):SvgPath{
		let cmd;
		const ret:Command[]=[];
		let args:number[]=[];
		let curArg='';
		let foundDecimal=false;
		let params=0;
		
		for (let c of path){
			if(parameterCounts[c] != null){
				params=parameterCounts[c];
			
				if(cmd){
					// save existing command
					if(curArg.length>0){
						args[args.length]=+curArg;
					}
			
					ret[ret.length]={
						cmd,
						args
					};
					args=[];
					curArg='';
					foundDecimal=false;
				}
			
				cmd=c;
			} else if([' ', ','].includes(c) || c === '-' && curArg.length>0 && curArg[curArg.length-1] !== 'e' || c === '.' && foundDecimal){
				if(curArg.length === 0){
					continue;
				}
			
				if(args.length === params){
					// handle reused commands
					ret[ret.length]={
						cmd,
						args
					};
					args=[+curArg];// handle assumed commands
			
					if(cmd === 'M'){
						cmd='L';
					}
			
					if(cmd === 'm'){
						cmd='l';
					}
				} else {
					args[args.length]=+curArg;
				}
		
				foundDecimal=c === '.';// fix for negative numbers or repeated decimals with no delimeter between commands
			
				curArg=['-', '.'].includes(c) ? c : '';
				} else {
				curArg+=c;
			
				if(c === '.'){
					foundDecimal=true;
				}
			}
		} // add the last command
		
		
		if(curArg.length>0){
			if(args.length === params){
				// handle reused commands
				ret[ret.length]={
					cmd,
					args
				};
				args=[+curArg];// handle assumed commands
			
				if(cmd === 'M'){
					cmd='L';
				}
			
				if(cmd === 'm'){
					cmd='l';
				}
			} else {
				args[args.length]=+curArg;
			}
		}
		
		ret[ret.length]={
			cmd,
			args
		};
		return new SvgPath(ret);
	}

}