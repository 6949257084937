export interface DrillAndBlastCostsParams {
	tonsPerHour: number;
	tonsPerHourGoal: number;
	id?: number;
	explosivesCostPerTon: number;
	explosivesCostPerTonGoal: number;
	drillingCostPerTon: number;
	drillingCostPerTonGoal: number;
	drillAndBlastCostPerTon: number;
	drillAndBlastCostPerTonGoal: number;
	powerConsumptionUnit: number;
	powerConsumptionCost: number;
	powerConsumptionCostGoal: number;
	totalCost: number;
	totalCostGoal: number;
	secondaryBreakageQuantity: number;
	secondaryBreakageCost: number;
	secondaryBreakageCostGoal: number;
	recordedAt: string;
	datasetName?: string;
	domainId?: number;
	subdomainId?: number;
	siteId?: number;
	apiV2DatasetId?: number;
	siteName?: string;
	totalBlastingCost: number;
	totalBlastingCostGoal: number;
	totalExplosiveCost: number;
	totalExplosiveCostGoal: number;
}

export interface DataSourceSchema {
    key: string;
    type: string;
    label: string;
    readonly?: boolean;
}

export const dataSourceCollectionSchema: DataSourceSchema[] = [
    { key: 'tonsPerHour', type: 'number', label: 'Tons Per Hour' },
    { key: 'tonsPerHourGoal', type: 'number', label: 'Tons Per Hour Goal' },
    { key: 'explosivesCostPerTon', type: 'number', label: 'Explosive Cost (Bulk and Packaged)' },
    { key: 'explosivesCostPerTonGoal', type: 'number', label: 'Explosives Cost (Bulk and Packaged) Goal' },
    { key: 'totalExplosiveCost', type: 'number', label: 'Total Explosive Cost (Booster + Detonator + Explosives)' },
    { key: 'totalExplosiveCostGoal', type: 'number', label: 'Total Explosive Cost Goal (Booster + Detonator + Explosives)' },
    { key: 'drillingCostPerTon', type: 'number', label: 'Drilling Cost' },
    { key: 'drillingCostPerTonGoal', type: 'number', label: 'Drilling Cost Goal' },
    { key: 'drillAndBlastCostPerTon', type: 'number', label: 'Drill and Total Explosives Cost' },
    { key: 'drillAndBlastCostPerTonGoal', type: 'number', label: 'Drill and Total Explosives Cost Goal' },
    { key: 'secondaryBreakageQuantity', type: 'number', label: 'Secondary Breakage Quantity' },
    { key: 'secondaryBreakageCost', type: 'number', label: 'Secondary Breakage Cost' },
    { key: 'secondaryBreakageCostGoal', type: 'number', label: 'Secondary Breakage Cost Goal' },
    { key: 'totalBlastingCost', type: 'number', label: 'Total Blasting Cost' },
    { key: 'totalBlastingCostGoal', type: 'number', label: 'Total Blasting Cost Goal' },
    { key: 'powerConsumptionUnit', type: 'number', label: 'Crusher Power Consumption Unit' },
    { key: 'powerConsumptionCost', type: 'number', label: 'Crusher Power Consumption Cost' },
    { key: 'powerConsumptionCostGoal', type: 'number', label: 'Crusher Power ConsumptionCost Goal' },
    { key: 'totalCost', type: 'number', label: 'Total Cost' },
    { key: 'totalCostGoal', type: 'number', label: 'Total Cost Goal' },
    { key: 'recordedAt', type: 'date', label: 'Recorded At' },
]